<template>
  <div>
    <BaseError 
      title="Error while registering the pattern" 
      :message="error" 
      :error="error"
      :fixed="false"
      :isConfirmation="true"
      @close="closeErrorDialog"
    />
    <BaseCard>
      <h1>
        <FontAwesomeIcon icon="fa-solid fa-section" /> Register Pattern
      </h1>
      <BaseSpinner v-if="isLoading" message="Loading..."/>
      <form v-else @submit.prevent="registerPattern">
        <div class="form-control" :class="{invalid: !nameIsValid}">
          <label for="name">Name</label>
          <input 
            type="text" 
            name="name" 
            id="name" 
            v-model.trim="name"
          >
          <p v-if="!nameIsValid">This field cannot be empty, please enter a valid name.</p>
        </div>
        <div class="form-control">
          <label for="description">Description (Optional)</label>
          <textarea 
            type="textarea" 
            name="description" 
            id="description" 
            rows="3" 
            v-model.trim="description">
          </textarea>
        </div>
        <div class="form-control" :class="{invalid: !kindIsValid}">
          <label for="name">Kind</label>
          <select 
            name="kind" 
            id="kind" 
            v-model="kind"
          >
            <option value="is-not-numerical-anomaly">Is Not Numerical Anomaly</option>
            <option value="is-between">Is Between</option>
            <option value="is-equal">Is Equal To</option>
            <option value="is-not-duplicated">Is Not Duplicated</option>
            <option value="is-greater-or-equal-than">Is Greater Or Equal Than</option>
            <option value="is-greater-than">Is Greater Than</option>
            <option value="is-in">Is In</option>
            <option value="is-less-or-equal-than">Is Less Or Equal Than</option>
            <option value="is-less-than">Is Less Than</option>
            <option value="is-not-missing">Is Not Missing</option>
            <option value="is-negative">Is Negative</option>
            <option value="is-positive">Is Positive</option>
            <option value="is-not-unique">Is Not Unique</option>
            <option value="is-date-type">Is Of Date Type</option>
            <option value="is-numerical-type">Is Of Numerical Type</option>
            <option value="matches-regex">Matches Regex</option>
          </select>
          <p v-if="!kindIsValid">Please select a pattern kind.</p>
        </div>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-between'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsBetweenExpectation 
              v-model:lowerBound="expectation.lowerBoundValue" 
              v-model:upperBound="expectation.upperBoundValue"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-greater-or-equal-than'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsGreaterOrEqualThanExpectation
              v-model:lowerBound="expectation.lowerBoundValue"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-greater-than'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsGreaterThanExpectation
              v-model:lowerBound="expectation.lowerBoundValue"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-less-or-equal-than'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsLessOrEqualThanExpectation
              v-model:upperBound="expectation.upperBoundValue"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-less-than'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsLessThanExpectation
              v-model:upperBound="expectation.upperBoundValue"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-in'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsInExpectation 
              v-model:values="expectation.values"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='matches-regex'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <MatchesRegexExpectation
              v-model:match="expectation.match"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-not-numerical-anomaly'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsNumericalAnomalyExpectation
              v-model:threshold="expectation.threshold"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-equal'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsEqualExpectation
            v-model:match="expectation.match"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-date-type'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsDateTypeExpectation
            v-model:match="expectation.match"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <transition name="show-expectation">
          <div v-if="showExpectation && kind==='is-numerical-type'" class="form-control" :class="{invalid: !expectationIsValid}">
            <label for="expectation">Expectation</label>
            <IsNumericalTypeExpectation
            v-model:decimals="expectation.decimals"
            />
            <p v-if="!expectationIsValid">Please set the expectation.</p>
          </div>
        </transition>
        <div class="controls">
          <BaseButton @click="registerPattern">Register</BaseButton>
        </div>
      </form>
    </BaseCard>
  </div>
  
</template>

<script>
import IsBetweenExpectation from '@/components/patterns/expectations/IsBetweenExpectation.vue'
import IsGreaterOrEqualThanExpectation from '@/components/patterns/expectations/IsGreaterOrEqualThanExpectation.vue'
import IsGreaterThanExpectation from '@/components/patterns/expectations/IsGreaterThanExpectation.vue'
import IsLessOrEqualThanExpectation from '@/components/patterns/expectations/IsLessOrEqualThanExpectation.vue'
import IsLessThanExpectation from '@/components/patterns/expectations/IsLessThanExpectation.vue'
import IsInExpectation from '@/components/patterns/expectations/IsInExpectation.vue'
import MatchesRegexExpectation from '@/components/patterns/expectations/MatchesRegexExpectation.vue'
import IsNumericalAnomalyExpectation from '@/components/patterns/expectations/IsNumericalAnomalyExpectation.vue'
import IsEqualExpectation from '@/components/patterns/expectations/IsEqualExpectation.vue'
import IsDateTypeExpectation from '@/components/patterns/expectations/IsDateTypeExpectation.vue'
import IsNumericalTypeExpectation from '@/components/patterns/expectations/IsNumericalTypeExpectation.vue'

export default {
  components: {
    IsBetweenExpectation,
    IsGreaterOrEqualThanExpectation,
    IsGreaterThanExpectation,
    IsLessOrEqualThanExpectation,
    IsLessThanExpectation, 
    IsInExpectation,
    MatchesRegexExpectation,
    IsNumericalAnomalyExpectation,
    IsEqualExpectation,
    IsDateTypeExpectation,
    IsNumericalTypeExpectation
  },
  computed: {
    showExpectation() {
      return this.kind && [
        'is-between', 
        'is-greater-than', 
        'is-greater-or-equal-than', 
        'is-less-than', 
        'is-less-or-equal-than',
        'is-equal',
        'matches-regex',
        'is-in',
        'is-not-numerical-anomaly',
        'is-date-type',
        'is-numerical-type'
      ].includes(this.kind)
    }
  },
  data() {
    return {
      name: '',
      nameIsValid: true,
      description: '',
      kind: null,
      kindIsValid: true,
      expectation: {},
      expectationIsValid: true,
      validForm: true,
      error: null,
      numberOfRequests: 0,
      isLoading: false
    }
  },
  methods: {
    validateForm() {
      if (this.name === '') {
        this.nameIsValid = false
      }
      if (!this.kind) {
        this.kindIsValid = false
      }
      this.expectationIsValid = this.validateExpectation()
      this.validForm = this.nameIsValid && this.kindIsValid && this.expectationIsValid
    },
    clearErrors() {
      this.nameIsValid = true
      this.kindIsValid = true
      this.expectationIsValid = true
      this.validForm = true
    },
    async registerPattern() {
      this.isLoading = true
      this.numberOfRequests++
      if (this.numberOfRequests > 1) {
        this.isLoading = false
        this.numberOfRequests = 0
        return
      }
      this.clearErrors()
      this.validateForm()
      if (this.validForm) {
        try {
          const parsedExpectation = this.parseExpectation()
          await this.$store.dispatch('patterns/registerPattern', {
            name: this.name,
            description: this.description === '' ? null : this.description,
            kind: this.kind,
            expectation: parsedExpectation
          })
          this.isLoading = false
          this.$router.replace('/patterns')
        } catch (err) {
          this.error = err.message
          this.numberOfRequests = 0
          this.isLoading = false
        }
      } else {
        this.isLoading = false
      }
    },
    validateExpectation() {
      if (this.kind === 'is-between') {
        return !!this.expectation.lowerBoundValue && !!this.expectation.upperBoundValue
      } else if (this.kind == 'is-greater-or-equal-than' || this.kind == 'is-greater-than') {
        return !!this.expectation.lowerBoundValue
      } else if (this.kind == 'is-less-or-equal-than' || this.kind == 'is-less-than') {
        return !!this.expectation.upperBoundValue
      } else if (this.kind === 'is-in') {
        return !!this.expectation.values && this.expectation.values.trim() !== ''
      } else if (this.kind === 'matches-regex' || this.kind === 'is-equal' || this.kind === 'is-date-type') {
        return !!this.expectation.match && this.expectation.match.trim() !== ''
      } else if (this.kind === 'is-not-numerical-anomaly') {
        return !!this.expectation.threshold
      } else if (this.kind === 'is-numerical-type') {
        return !!this.expectation.decimals && (this.expectation.decimals <= 20)
      } else {
        return true
      }
    },
    parseExpectation() {
      if (this.kind === 'is-between') {
        return [
          parseFloat(this.expectation.lowerBoundValue),
          parseFloat(this.expectation.upperBoundValue) 
        ]
      } else if (this.kind == 'is-greater-or-equal-than' || this.kind == 'is-greater-than') {
        return parseFloat(this.expectation.lowerBoundValue)
      } else if (this.kind == 'is-less-or-equal-than' || this.kind == 'is-less-than') {
        return parseFloat(this.expectation.upperBoundValue)
      } else if (this.kind === 'is-in') {
        return this.expectation.values.split('\n')
      } else if (this.kind === 'matches-regex' || this.kind === 'is-equal' || this.kind === 'is-date-type') {
        return this.expectation.match
      } else if (this.kind === 'is-not-numerical-anomaly') {
        return {
          kind: 'zscore', 
          params: {
            threshold: parseFloat(this.expectation.threshold)
          }
        }
      } else if (this.kind === 'is-numerical-type') {
        return parseInt(this.expectation.decimals)
      } else {
        return null
      }
    },
    closeErrorDialog() {
      this.error = null
    }
  }
}
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input, textarea, select {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  background-color: white;
  border-radius: 0.2rem;
}

.invalid label, .invalid p {
  color: red;
}

.invalid input {
  border: 1px solid red;
}

.controls {
  text-align: right;
}

.show-expectation-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.show-expectation-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.show-expectation-enter-active {
  transition: all 0.3s ease-out;
}

.show-expectation-leave-active {
  transition: all 0.3s ease-in;
}

.show-expectation-enter-to,
.show-expectation-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>