export default {
  setRegulations(state, payload) {
    state.regulations = payload
  },
  registerRegulation(state, payload) {
    state.regulations.push(payload)
  },
  deleteRegulation(state, payload) {
    state.regulations = state.regulations.filter(regulation => regulation.id !== payload.id)
  },
  updateRegulation(state, payload) {
    const index = state.regulations.findIndex(regulation => regulation.id == payload.id)
    state.regulations[index].name = payload.name
    state.regulations[index].description = payload.description
  },
  setRegulation(state, payload) {
    state.regulation = payload
  },
  deletePattern(state, payload) {
    state.regulation.patterns = state.regulation.patterns.filter(pattern => pattern.id !== payload.patternId)
  },
  addPattern(state, payload) {
    state.regulation.patterns.push(payload.pattern)
  },
  updatePattern(state, payload) {
    const index = state.regulation.patterns.findIndex(pattern => pattern.id === payload.patternId)
    state.regulation.patterns[index].affectedColumns = payload.affectedColumns
    state.pattern.affectedColumns = payload.affectedColumns
  },
  setPattern(state, payload) {
    state.pattern = payload
  }
}