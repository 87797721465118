<template>
  <h4 class="pattern-expectation" v-if="kind === 'is-greater-than'">
      <FontAwesomeIcon icon="fa-solid fa-circle-check" /> 
      value &gt;
      <span class="single-value">{{ expectation }}</span>
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'is-between'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> 
    <span class="single-value">{{ expectation[0] }}</span> 
    &le; value &le; 
    <span class="single-value">{{ expectation[1] }}</span>
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'is-in'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> value is {{expectation.join(', ')}}
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'is-greater-or-equal-than'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> value &ge;
    <span class="single-value">{{ expectation }}</span>
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'is-equal'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> value = 
    <span class="single-value">{{ expectation }}</span>
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'is-less-or-equal-than'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> value &le; 
    <span class="single-value">{{ expectation }}</span>
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'is-less-than'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> value &lt; 
    <span class="single-value">{{ expectation }}</span>
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'matches-regex'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> value matches regex
    <span class="single-value">{{ expectation }}</span>
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'is-not-numerical-anomaly'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> z-score &lt;
    <span class="single-value">{{ expectation.params.threshold }}</span>
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'is-date-type'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> value matches:
    <span class="single-value">{{ expectation }}</span>
  </h4>
  <h4 class="pattern-expectation" v-else-if="kind === 'is-numerical-type'">
    <FontAwesomeIcon icon="fa-solid fa-circle-check" /> value number of decimals:
    <span class="single-value">{{ expectation }}</span>
  </h4>
</template>

<script>
export default {
  props: ['kind', 'expectation']
}
</script>

<style scoped>
.pattern-expectation {
  background-color: rgb(230, 230, 230);
  border-radius: 1rem;
  padding: 0.5rem;
  overflow-wrap: anywhere;
  text-align: center;
  width: fit-content;
}

</style>