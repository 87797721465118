<template>
  <div>
    <BaseError 
      title="Error while fetching the patterns" 
      :message="error" 
      :error="error"
      :fixed="true"
      :isConfirmation="false"
    />
    <BaseCard v-if="!error">
      <h2>
        <FontAwesomeIcon icon="fa-solid fa-section" /> Patterns
      </h2>
      <BaseSpinner v-if="isLoading" message="Loading..."/>
      <div v-else>
        <div class="controls">
          <BaseButton @click="registerPattern">
            <FontAwesomeIcon icon="fa-solid fa-plus" /> New
          </BaseButton>
        </div>
        <transition-group tag="ul" name="pattern-list">
          <PatternItem
            v-for="pattern in allPatterns" 
            :key="pattern.id" 
            :pattern="pattern"
            :fromRegulation="false"
          />
        </transition-group>
        <p v-if="!error && !hasPatterns">You don't have any patterns yet, add some to start the Data Quality journey!</p>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import PatternItem from '@/components/patterns/PatternItem.vue'

export default {
  components: {
    PatternItem
  },
  data() {
    return {
      error: null,
      isLoading: true
    }
  },
  computed: {
    allPatterns() {
      return this.$store.getters['patterns/patterns']
    },
    hasPatterns() {
      const patterns = this.$store.getters['patterns/patterns']
      return patterns && patterns.length > 0
    }
  },
  methods: {
    registerPattern() {
      this.$router.replace('/patterns/register')
    }
  },
  async created() {
    try {
      this.isLoading = true
      await this.$store.dispatch('patterns/loadPatterns')
      this.isLoading = false
    } catch(err) {
      this.error = err.message
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  text-align: right;
}

h2 {
  margin-bottom: 0;
}

.pattern-list-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.pattern-list-leave-active {
  transition: all 1s ease-in;
  position: absolute;
}

.pattern-list-leave-to {
  opacity: 0;
  transform: translateX(60px);
}

.pattern-list-move {
  transition: transform 1s ease;
}
</style>