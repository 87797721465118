<template>
  <h5>{{ name }}</h5>
  <div class="score-bar-container" :style="barStyle">
    <p class="score-bar-value">{{ scoreFormatted }}</p>
  </div>
</template>

<script>
export default {
  props: ['name', 'score'],
  computed: {
    barStyle() {
      let color
      if (this.score >= 0.75) {
        color = 'rgb(102, 213, 156)'
      } else if (this.score >= 0.5 && this.score < 0.75) {
        color = 'rgb(255, 246, 124)'
      } else if (this.score > 0.25 && this.score < 0.5) {
        color = 'rgb(255, 209, 111)'
      } else {
        color = 'rgb(255, 125, 125)'
      }

      return {width: this.score * 93 + '%', backgroundColor: color}
    },
    scoreFormatted() {
      return (100 * this.score).toFixed(1) + '%'
    }
  }
}
</script>

<style scoped>
h5 {
  margin-top: auto;
  margin-bottom: auto;
}

.score-bar-container {
  height: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  border-radius: 1rem;
  text-align: right;
  max-width: 93%;
}

.score-bar-value {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-weight: 1000;
  margin-right: -3.5rem;
}
</style>