<template>
   <div class="expectation-container">
      <p>Values match</p>
      <input 
        type="text" 
        name="match" 
        id="match" 
        :value="match" 
        @input="$emit('update:match', $event.target.value)"
      >
    </div>
</template>

<script>
export default {
  props: ['match']
}
</script>

<style scoped>
#match {
  width: 20%;
  height: 50%;
  margin-top: 0;
  margin-bottom: auto;
  margin-left: 0.5rem;
}

input {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  background-color: white;
  border-radius: 0.5rem;
}

.expectation-container {
  display: flex;
}

p {
  margin-top: 0;
}
</style>