<template>
  <div class="spinner-container">
    <div class="spinner">
      <video src="/loading 2.mp4" loop autoplay/>
    </div>
    <h3 class="blink">{{ message }}</h3>
  </div>
</template>

<script>
export default {
  props: ['message']
}
</script>

<style scoped>
.blink {
  animation: blink 1.5s linear infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.spinner-container {
  text-align: center;
}

.spinner {
  background-color: rgb(120, 106, 252);
  width: 9rem;
  margin: auto;
  border-radius: 50%;
}

video {
  height: 9rem;
  border-radius: 50%;
  padding: 1rem;
  margin-right: 0.2rem;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>