
import { createApp } from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { 
  faFileContract, 
  faTable, 
  faMedal, 
  faTableColumns, 
  faSection, 
  faA,
  faB,
  faC,
  faD,
  faF,
  faEye,
  faPenToSquare,
  faTrash,
  faPlus,
  faPlay,
  faBookmark,
  faCircleCheck,
  faSquarePollVertical,
  faCircleXmark,
  faHome,
  faTriangleExclamation,
  faTableCells,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'

import App from './App.vue'
import BaseButton from './components/ui/BaseButton.vue'
import BaseCard from './components/ui/BaseCard.vue'
import BaseDeleteConfirmation from './components/ui/BaseDeleteConfirmation'
import BaseDialog from './components/ui/BaseDialog'
import BaseError from './components/ui/BaseError'
import BaseSpinner from './components/ui/BaseSpinner'
import TheHeader from './components/layout/TheHeader.vue'

import router from './router'
import store from './store'

library.add(
  faFileContract, 
  faTable, 
  faMedal, 
  faTableColumns, 
  faSection, 
  faA, 
  faB, 
  faC, 
  faC, 
  faD, 
  faF,
  faEye,
  faPenToSquare,
  faTrash,
  faPlus,
  faPlay,
  faBookmark,
  faCircleCheck,
  faSquarePollVertical,
  faCircleXmark,
  faHome,
  faTriangleExclamation,
  faTableCells,
  faEyeSlash
)

const app = createApp(App)

app.component('BaseButton', BaseButton)
app.component('BaseCard', BaseCard)
app.component('BaseDeleteConfirmation', BaseDeleteConfirmation)
app.component('BaseDialog', BaseDialog)
app.component('BaseError', BaseError)
app.component('BaseSpinner', BaseSpinner)
app.component('TheHeader', TheHeader)
app.component('FontAwesomeIcon', FontAwesomeIcon)

app.use(router).use(store).mount('#app')
