<template>
   <div class="expectation-container">
      <p>Values &gt;</p>
      <input 
        type="number" 
        name="lower-bound" 
        id="lower-bound" 
        :value="lowerBound" 
        @input="$emit('update:lowerBound', $event.target.value)"
      >
    </div>
</template>

<script>
export default {
  props: ['lowerBound']
}
</script>

<style scoped>
#lower-bound {
  width: 20%;
  height: 50%;
  margin-top: 0;
  margin-bottom: auto;
  margin-left: 0.5rem;
}

input {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  background-color: white;
  border-radius: 0.5rem;
}

.expectation-container {
  display: flex;
}

p {
  margin-top: 0;
}
</style>