<template>
  <div>
    <BaseError 
      title="Error while registering the regulation" 
      :message="error" 
      :error="error"
      :fixed="false"
      :isConfirmation="true"
      @close="closeErrorDialog"
    />
    <BaseCard>
      <h1>
        <FontAwesomeIcon icon="fa-solid fa-file-contract" /> Register Regulation
      </h1>
      <BaseSpinner v-if="isLoading" message="Loading..."/>
      <form v-else @submit.prevent="registerRegulation">
        <div class="form-control" :class="{invalid: !nameIsValid}">
          <label for="name">Name</label>
          <input 
            type="text" 
            name="name" 
            id="name" 
            v-model.trim="name"
          />
          <p v-if="!nameIsValid">This field cannot be empty, please enter a valid name.</p>
        </div>
        <div class="form-control">
          <label for="description">Description (Optional)</label>
          <textarea 
            type="textarea" 
            name="description" 
            id="description" 
            rows="3" 
            v-model.trim="description"
          />
        </div>
        <div class="form-control" :class="{invalid: !associatedTableIsValid}">
          <label for="name">Associated table</label>
          <select 
            name="associatedTable" 
            id="associatedTable" 
            v-model="associatedTable"
          >
            <option 
              v-for="table in availableTables" 
              :key="table.id"
              :value="table.name"
            >
              {{ table.name }}
            </option>
          </select>
          <p v-if="!associatedTableIsValid">Please select the associated table.</p>
        </div>
        <div class="controls">
          <BaseButton @click="registerRegulation">Register</BaseButton>
        </div>
      </form>
    </BaseCard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      description: '',
      associatedTable: null,
      nameIsValid: true,
      associatedTableIsValid: true,
      validForm: true,
      error: null,
      numberOfRequests: 0,
      isLoading: false
    }
  },
  computed: {
    availableTables() {
      return this.$store.getters['tables/tables']
    }
  },
  methods: {
    validateForm() {
      if (this.name === '') {
        this.nameIsValid = false
      }
      if (!this.associatedTable) {
        this.associatedTableIsValid = false
      }
      this.validForm = this.nameIsValid && this.associatedTableIsValid
    },
    clearErrors() {
      this.nameIsValid = true
      this.associatedTableIsValid = true
      this.validForm = true
    },
    async registerRegulation() {
      this.isLoading = true
      this.numberOfRequests++
      if (this.numberOfRequests > 1) {
        this.isLoading = false
        this.numberOfRequests = 0
        return
      }
      this.clearErrors()
      this.validateForm()

      if (this.validForm) {
        try {
          await this.$store.dispatch('regulations/registerRegulation', {
            name: this.name,
            description: this.description === '' ? null : this.description,
            associatedTable: this.associatedTable
          })
          this.isLoading = false
          this.$router.replace("/regulations")
        } catch (err) {
          this.error = err.message
          this.numberOfRequests = 0
          this.isLoading = false
        }
      }
      this.isLoading = false
    },
    closeErrorDialog() {
      this.error = null
    }
  }
}
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input, textarea, select {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  background-color: white;
  border-radius: 0.2rem;
}

.invalid label, .invalid p {
  color: red;
}

.invalid input {
  border: 1px solid red;
}

.controls {
  text-align: right;
}
</style>