import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export default {
  namespaced: true,
  state() {
    return {
      tables: [
        {
          id: 0,
          name: "Desk Checking",
          columns: ["ID", "gender", "age", "salary"],
          uri: "gs://auto_data_quality/deskchecking",
          samples: [
            {id: 0, content: {0: 6, 1: "M", 2: 35.79, 3: 560}},
            {id: 1, content: {0: 7, 1: "is-in", 2: 43.43, 3: 383}},
            {id: 2, content: {0: 8, 1: "M", 2: 56.66, 3: 266}},
            {id: 3, content: {0: 9, 1: "F", 2: 29.66, 3: 530}},
            {id: 4, content: {0: 4, 1: "is-in", 2: 17.0, 3: 99}}
          ],
          numberOfRows: 10
        },
        {
          id: 1,
          name: "Air Quality Index",
          columns: ["country", "city", "aqi-value", "aqi-category", "co-aqi-value", "co-aqi-category", "ozone-aqi-value", "ozone-aqi-category", "no2-aqi-value", "no2-aqi-category", "pm2-5-aqi-value", "pm2-5-aqi-category", "lat", "lng"],
          uri: "gs://auto_data_quality/air_quality_index_data",
          samples: [
            {id: 0, content: {0: "Russian", 1: "Federation Praskoveya", 2: 51, 3: "Moderate", 4: 1, 5: "Good", 6:36, 7: "Good", 8: 0, 9: "Good", 10: 51, 11: "Moderate", 12: 44.7444, 13: 44.2031}},
            {id: 1, content: {0: "Brazil", 1: "Presidente Dutra", 2: 41, 3: "Good", 4: 1, 5: "Good", 6:5, 7: "Good", 8: 1, 9: "Good", 10: 41, 11: "Good", 12: -5.29 , 13:-44.49}},
            {id: 2, content: {0: "Brazil", 1: "Presidente Dutra", 2: 41, 3: "Good", 4: 1, 5: "Good", 6:5, 7: "Good", 8: 1, 9: "Good", 10: 41, 11: "Good", 12: -11.295, 13:8 -41.9869}},
            {id: 3, content: {0: "Italy", 1: "Priolo Gargallo", 2: 66, 3: "Moderate", 4: 1, 5: "Good", 6:39, 7: "Good", 8: 2, 9: "Good", 10: 66, 11: "Moderate", 12: 37.1667, 13: 15.1833}},
            {id: 4, content: {0: "Poland", 1: "Przasnysz", 2: 34, 3: "Good", 4: 1, 5: "Good", 6:34,  7: "Good", 8: 0, 9: "Good", 10: 20, 11: "Good", 12: 53.0167, 13: 20.8833}},

          ],
          numberOfRows: 16696
        },
        {
          id: 2,
          name: "Customers Health Insurance ANS",
          columns: ["id_cmpt", "sexo", "tp_vigenci", "sg_uf", "cd_capital", "cd_rm", "cd_abrg", "cd_contr", "cd_segment", "modalidade", "cd_faixa_e", "nr_benef_t", "nr_benef_m", "nr_benef_o"],
          uri: "gs://auto_data_quality/customers_health_insurance_ans",
          samples: [
            {id: 0, content: {0: 202212, 1: "M", 2: "P", 3: "RJ", 4: 0, 5: 0, 6: 1, 7: 2, 8: 7, 9: 22, 10: 99, 11: 2, 12: 2, 13: 0}},
            {id: 1, content: {0: 202212, 1: "M", 2: "A", 3: "MG", 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 27, 10: 99, 11: 1, 12: 1, 13: 0}},
            {id: 2, content: {0: 202212, 1: "M", 2: "A", 3: "BA", 4: 0, 5: 0, 6: 1, 7: 1, 8: 7, 9: 22, 10: 99, 11: 1, 12: 1, 13: 0}},
            {id: 3, content: {0: 202212, 1: "M", 2: "P", 3: "MS", 4: 0, 5: 0, 6: 1, 7: 2, 8: 4, 9: 26, 10: 99, 11: 1, 12: 0, 13: 1}},
            {id: 4, content: {0: 202212, 1: "M", 2: "P", 3: "RS", 4: 431490, 5: 4301, 6: 2, 7: 2, 8: 4, 9: 23, 10: 99, 11: 1, 12: 0, 13: 1}}
          ],
          numberOfRows: 549522
        },
        {
          id: 3,
          name: "Network Access Data",
          columns: ["timestamp", "sourceip", "destinationip", "dnsquery", "dnsanswer", "dnsanswerttl", "dnsquerynames", "dnsqueryclass", "dnsquerytype", "numberofanswers", "dnsresponsecode", "dnsopcode", "sensorid", "sus", "evil"],
          uri: "gs://auto_data_quality/network_access_data",
          samples: [
            { id: 0, content: {0: "2021-05-16T17:13:14Z", 1: "10.100.1.95", 2: "10.100.0.2", 3: "ssm.us-east-2.amazonaws.com", 4: null, 5: null, 6: "ssm.us-east-2.amazonaws.com", 7: ["IN"], 8: ["A"], 9: 0, 10: 0, 11: 0, 12: "ip-10-100-1-95", 13: 0, 14: 0}},
            { id: 0, content: {0: "2021-05-16T17:13:14Z", 1: "10.100.0.2", 2: "10.100.1.95", 3: "ssm.us-east-2.amazonaws.com", 4: ["52.95.19.240"], 5: ["17"], 6: "ssm.us-east-2.amazonaws.com", 7: ["IN"], 8: ["A"], 9: 1, 10: 0, 11: 0, 12: "ip-10-100-1-95", 13: 0, 14: 0}},
            { id: 0, content: {0: "2021-05-16T17:13:14Z", 1: "10.100.1.95", 2: "10.100.0.2", 3: "ssm.us-east-2.amazonaws.com", 4: null, 5: null, 6: "ssm.us-east-2.amazonaws.com", 7: ["IN"], 8: ["AAAA"], 9: 0, 10: 0, 11: 0, 12: "ip-10-100-1-95", 13: 0, 14: 0}},
            { id: 0, content: {0: "2021-05-16T17:13:14Z", 1: "10.100.0.2", 2: "10.100.1.95", 3: "ssm.us-east-2.amazonaws.com", 4: null, 5: null, 6: "ssm.us-east-2.amazonaws.com", 7: ["IN"], 8: ["AAAA"], 9: 0, 10: 0, 11: 0, 12: "ip-10-100-1-95", 13: 0, 14: 0}},
            { id: 0, content: {0: "2021-05-16T17:13:16Z", 1: "10.100.1.186", 2: "10.100.0.2", 3: "ssm.us-east-2.amazonaws.com", 4: null, 5: null, 6: "ssm.us-east-2.amazonaws.com", 7: ["IN"], 8: ["A"], 9: 0, 10: 0, 11: 0, 12: "ip-10-100-1-186", 13: 0, 14: 0}},
          ],
          numberOfRows: 270,
        },
        {
          id: 4,
          name: "Salary Data",
          columns: ["age", "gender", "education-level", "job-title", "years-of-experience", "salary"],
          uri: "gs://auto_data_quality/salary_data",
          samples: [
            {id: 0, content: {0: 32, 1:"Male", 2:"Bachelor's", 3:"Software Engineer", 4:5, 5:90000}},
            {id: 1, content: {0: 28, 1:"Female", 2:"Master's", 3:"Data Analyst", 4:3, 5:65000}},
            {id: 2, content: {0: 45, 1:"Male", 2:"PhD", 3:"Senior Manager", 4:15, 5:150000}},
            {id: 3, content: {0: 36, 1:"Female", 2:"Bachelor's", 3:"Sales Associate", 4:7, 5:60000}},
            {id: 4, content: {0: 52, 1:"Male", 2:"Master's", 3:"Director", 4:20, 5:200000}}
          ],
          numberOfRows: 6705
        },
        {
          id: 5,
          name: "Base Pública SUS Paraíba 2012 ano mês 3",
          columns: [
              "PA_CODUNI",
              "PA_GESTAO",
              "PA_CONDIC",
              "PA_UFMUN",
              "PA_REGCT",
              "PA_INCOUT",
              "PA_INCURG",
              "PA_TPUPS",
              "PA_TIPPRE",
              "PA_MN_IND",
              "PA_CNPJCPF",
              "PA_CNPJMNT",
              "PA_CNPJ_CC",
              "PA_MVM",
              "PA_CMP",
              "PA_PROC_ID",
              "PA_TPFIN",
              "PA_SUBFIN",
              "PA_NIVCPL",
              "PA_DOCORIG",
              "PA_AUTORIZ",
              "PA_CNSMED",
              "PA_CBOCOD",
              "PA_MOTSAI",
              "PA_OBITO",
              "PA_ENCERR",
              "PA_PERMAN",
              "PA_ALTA",
              "PA_TRANSF",
              "PA_CIDPRI",
              "PA_CIDSEC",
              "PA_CIDCAS",
              "PA_CATEND",
              "PA_IDADE",
              "IDADEMIN",
              "IDADEMAX",
              "PA_FLIDADE",
              "PA_SEXO",
              "PA_RACACOR",
              "PA_MUNPCN",
              "PA_QTDPRO",
              "PA_QTDAPR",
              "PA_VALPRO",
              "PA_VALAPR",
              "PA_UFDIF",
              "PA_MNDIF",
              "PA_DIF_VAL",
              "NU_VPA_TOT",
              "NU_PA_TOT",
              "PA_INDICA",
              "PA_CODOCO",
              "PA_FLQT",
              "PA_FLER",
              "PA_ETNIA",
              "PA_VL_CF",
              "PA_VL_CL",
              "PA_VL_INC",
              "PA_SRV_C",
              "PA_INE",
              "PA_NAT_JUR"
            ],
            uri: "gs://auto_data_quality/siasus",
            samples: [
              {
                id: 0,
                content: {
                  0: "7052251",
                  1: "250400",
                  2: "PG",
                  3: "250400",
                  4: "7114",
                  5: "0000",
                  6: "0000",
                  7: "73",
                  8: "00",
                  9: "M",
                  10: "08993917000146",
                  11: "08993917000146",
                  12: "00000000000000",
                  13: "202303",
                  14: "202303",
                  15: "0202031209",
                  16: "06",
                  17: "0000",
                  18: "2",
                  19: "I",
                  20: "0000000000000",
                  21: "701207023269313",
                  22: "223415",
                  23: "00",
                  24: "0",
                  25: "0",
                  26: "0",
                  27: "0",
                  28: "0",
                  29: "Z017",
                  30: "0000",
                  31: "0000",
                  32: "02",
                  33: "078",
                  34: "0  ",
                  35: "130",
                  36: "1",
                  37: "F",
                  38: "03",
                  39: "250400",
                  40: "          1",
                  41: "          1",
                  42: "                9.00",
                  43: "                9.00",
                  44: "0",
                  45: "0",
                  46: "                0.00",
                  47: "                0.00",
                  48: "                9.00",
                  49: "5",
                  50: "1",
                  51: "R",
                  52: "0",
                  53: "",
                  54: "                0.00",
                  55: "                0.00",
                  56: "                0.00",
                  57: "",
                  58: "",
                  59: "1244"
                }
              },
              {
                id: 1,
                content: {
                  0: "3191877",
                  1: "250400",
                  2: "PG",
                  3: "250400",
                  4: "0000",
                  5: "0000",
                  6: "0000",
                  7: "39",
                  8: "00",
                  9: "I",
                  10: "08716557000135",
                  11: "00000000000000",
                  12: "00000000000000",
                  13: "202303",
                  14: "202303",
                  15: "0207010030",
                  16: "06",
                  17: "0000",
                  18: "3",
                  19: "I",
                  20: "0000000000000",
                  21: "107988055840003",
                  22: "225320",
                  23: "00",
                  24: "0",
                  25: "0",
                  26: "0",
                  27: "0",
                  28: "0",
                  29: "G35 ",
                  30: "0000",
                  31: "0000",
                  32: "01",
                  33: "064",
                  34: "0  ",
                  35: "130",
                  36: "1",
                  37: "F",
                  38: "03",
                  39: "250400",
                  40: "          1",
                  41: "          1",
                  42: "              268.75",
                  43: "              268.75",
                  44: "0",
                  45: "0",
                  46: "                0.00",
                  47: "                0.00",
                  48: "              268.75",
                  49: "5",
                  50: "1",
                  51: "K",
                  52: "0",
                  53: "",
                  54: "                0.00",
                  55: "                0.00",
                  56: "                0.00",
                  57: "121004",
                  58: "",
                  59: "2062"
                }
              },
              {
                id: 2,
                content: {
                  0: "3939049",
                  1: "250400",
                  2: "PG",
                  3: "250400",
                  4: "0000",
                  5: "0000",
                  6: "0000",
                  7: "36",
                  8: "00",
                  9: "I",
                  10: "05623324000108",
                  11: "00000000000000",
                  12: "00000000000000",
                  13: "202303",
                  14: "202303",
                  15: "0211060259",
                  16: "06",
                  17: "0000",
                  18: "2",
                  19: "I",
                  20: "0000000316750",
                  21: "705001479484550",
                  22: "225265",
                  23: "00",
                  24: "0",
                  25: "0",
                  26: "0",
                  27: "0",
                  28: "0",
                  29: "R688",
                  30: "0000",
                  31: "0000",
                  32: "01",
                  33: "056",
                  34: "0  ",
                  35: "130",
                  36: "1",
                  37: "M",
                  38: "04",
                  39: "250400",
                  40: "          1",
                  41: "          1",
                  42: "                3.37",
                  43: "                3.37",
                  44: "0",
                  45: "0",
                  46: "                0.00",
                  47: "                0.00",
                  48: "                3.37",
                  49: "5",
                  50: "1",
                  51: "K",
                  52: "0",
                  53: "",
                  54: "                0.00",
                  55: "                0.00",
                  56: "                0.00",
                  57: "",
                  58: "",
                  59: "3069"
                }
              },
              {
                id: 3,
                content: {
                  0: "5214815",
                  1: "250400",
                  2: "PG",
                  3: "250400",
                  4: "0000",
                  5: "0000",
                  6: "0000",
                  7: "04",
                  8: "00",
                  9: "I",
                  10: "01627117000162",
                  11: "00000000000000",
                  12: "00000000000000",
                  13: "202303",
                  14: "202303",
                  15: "0203010086",
                  16: "06",
                  17: "0000",
                  18: "2",
                  19: "I",
                  20: "0000000000000",
                  21: "702607240586648",
                  22: "223415",
                  23: "00",
                  24: "0",
                  25: "0",
                  26: "0",
                  27: "0",
                  28: "0",
                  29: "0000",
                  30: "0000",
                  31: "0000",
                  32: "01",
                  33: "041",
                  34: "25 ",
                  35: "64 ",
                  36: "1",
                  37: "F",
                  38: "01",
                  39: "250400",
                  40: "          1",
                  41: "          1",
                  42: "               14.37",
                  43: "               14.37",
                  44: "0",
                  45: "0",
                  46: "                0.00",
                  47: "                0.00",
                  48: "               14.37",
                  49: "5",
                  50: "1",
                  51: "K",
                  52: "0",
                  53: "",
                  54: "                0.00",
                  55: "                0.00",
                  56: "                0.00",
                  57: "120002",
                  58: "",
                  59: "3069"
                }
              },
              {
                id: 4,
                content: {
                  0: "7052251",
                  1: "250400",
                  2: "PG",
                  3: "250400",
                  4: "7114",
                  5: "0000",
                  6: "0000",
                  7: "73",
                  8: "00",
                  9: "M",
                  10: "08993917000146",
                  11: "08993917000146",
                  12: "00000000000000",
                  13: "202303",
                  14: "202303",
                  15: "0214010120",
                  16: "07",
                  17: "0000",
                  18: "1",
                  19: "I",
                  20: "0000000000000",
                  21: "701207023269313",
                  22: "223415",
                  23: "00",
                  24: "0",
                  25: "0",
                  26: "0",
                  27: "0",
                  28: "0",
                  29: "Z017",
                  30: "0000",
                  31: "0000",
                  32: "02",
                  33: "020",
                  34: "0  ",
                  35: "130",
                  36: "1",
                  37: "F",
                  38: "03",
                  39: "250400",
                  40: "          1",
                  41: "          1",
                  42: "                0.00",
                  43: "                0.00",
                  44: "0",
                  45: "0",
                  46: "                0.00",
                  47: "                0.00",
                  48: "                0.00",
                  49: "5",
                  50: "1",
                  51: "K",
                  52: "0",
                  53: "",
                  54: "                0.00",
                  55: "                0.00",
                  56: "                0.00",
                  57: "",
                  58: "",
                  59: "1244"
                }
              },
              ],
          numberOfRows: 6705
        }
      ]
    }
  },
  mutations,
  actions,
  getters
}


