<template>
  <header>
    <nav>
      <h1>
        <router-link to="/">Semantix Auto Data Quality</router-link>
      </h1>
      <ul>
        <li>
          <router-link to="/tables">
            <FontAwesomeIcon icon="fa-solid fa-table" /> Tables
          </router-link>
        </li>
        <li>
          <router-link to="/regulations">
            <FontAwesomeIcon icon="fa-solid fa-file-contract" /> Regulations
          </router-link>
        </li>
        <li>
          <router-link to="/patterns">
            <FontAwesomeIcon icon="fa-solid fa-section" /> Patterns
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>


<style scoped>
header {
  width: 100%;
  /* height: 5rem; */
  background-color: rgb(127, 66, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
}

header a {
  text-decoration: none;
  color: white;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
  border-radius: 2rem;
  font-weight: 1000;
}

a:hover {
  opacity: 0.7;
  transition: 0.3s;
}

h1 {
  margin: 0;
}

h1 a {
  color: white;
  margin: 0;
  font-size: 35px;
}

header nav {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}

@media screen and (max-width: 700px) {
  header ul {
    display: block;
  }
}
</style>