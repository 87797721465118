<template>
  <div>
    <BaseError 
      title="Error while updating the pattern" 
      :message="error" 
      :error="error"
      :fixed="false"
      :isConfirmation="true"
      @close="closeErrorDialog"
    />
    <BaseCard>
      <h1>
        <FontAwesomeIcon icon="fa-solid fa-section" /> Update Pattern
      </h1>
      <BaseSpinner v-if="isLoading" message="Loading..."/>
      <div v-else>
        <h4>
          {{pattern.name}}
        </h4>
        <p>{{pattern.description}}</p>
        <form @submit.prevent="updatePatternAttachedToRegulation">
          <div class="form-control" :class="{invalid: !affectedColumnsIsValid}">
            <h3>Affected Columns</h3>
            <div 
              v-for="column in allColumns" 
              :key="column" 
              class="checkbox-item"
            >
              <input 
                type="checkbox" 
                :id="column" 
                :value="column" 
                v-model="affectedColumns"
              >
              <label :for="column">{{ column }}</label>
            </div>
            <p v-if="!affectedColumnsIsValid">Choose at least one column.</p>
          </div>
          <div class="controls">
            <BaseButton>Update</BaseButton>
          </div>
        </form>
      </div>
    </BaseCard>
  </div>
</template>

<script>
export default {
  props: ['patternId', 'regulationId'],
  data() {
    return {
      regulation: {},
      pattern: {},
      affectedColumns: [],
      affectedColumnsIsValid: true,
      error: null,
      fetchingError: false,
      numberOfRequests: 0,
      isLoading: true
    }
  },
  computed: {
    allColumns() {
      const table = this.$store.getters['tables/tables'].find(table => table.name === this.regulation.associatedTable)
      if (!table) {
        return null
      }
      return table.columns
    }
  },
  methods: {
    validateForm() {
      this.affectedColumnsIsValid = this.affectedColumns.length !== 0
    },
    clearErrors() {
      this.affectedColumnsIsValid = true
    },
    async updatePatternAttachedToRegulation() {
      this.isLoading = true
      this.numberOfRequests++
      if (this.numberOfRequests > 1) {
        this.isLoading = false
        this.numberOfRequests = 0
        return
      }
      this.clearErrors()
      this.validateForm()

      if (this.affectedColumnsIsValid) {
        const regulationId = parseInt(this.regulationId)
        const patternId = parseInt(this.patternId)

        try {
          await this.$store.dispatch('regulations/updatePattern', {
            regulationId: regulationId,
            patternId: patternId,
            affectedColumns: this.affectedColumns
          })
          this.$router.push('/regulations/' + this.regulation.id)
        } catch (err) {
          this.error = err.message
          this.numberOfRequests = 0
        }
      } else {
        this.numberOfRequests = 0
      }
      this.isLoading = false
    },
    closeErrorDialog() {
      if (this.fetchingError) {
        this.$router.replace('/patterns')
      } else {
        this.error = null
      }
    },
  },
  async created() {
    this.isLoading = true
    const regulation = this.$store.getters['regulations/regulation']
    if (!Object.keys(regulation).length) {
      try {
        await this.$store.dispatch('regulations/loadRegulationById', {
          id: this.regulationId
        })
      } catch (err) {
        this.error = err.message
        this.fetchingError = true
        this.isLoading = false
        return 
      }
    }
    this.regulation = this.$store.getters['regulations/regulation']

    const pattern = this.$store.getters['regulations/pattern']
    if (!Object.keys(pattern).length) {
      try {
        await this.$store.dispatch('regulations/loadPattern', {
          regulationId: this.regulationId,
          patternId: this.patternId
        })
      } catch (err) {
        this.error = err.message
        this.fetchingError = true
        this.isLoading = false
        return 
      }
    }
    this.pattern = this.$store.getters['regulations/pattern']
    this.affectedColumns = this.pattern.affectedColumns
    this.isLoading = false
  }
}
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type='checkbox'] {
  display: inline;
  width: auto;
  margin-right: 0.2rem;
  accent-color: rgb(127, 66, 245);
}

.checkbox-item {
  display: flex;
}

.invalid label, .invalid p, .invalid h3 {
  color: red;
}

.invalid input {
  border: 1px solid red;
}

.controls {
  text-align: right;
}

h4 {
  background-color: rgb(245, 245, 245);
  width: fit-content;
  padding: 0.5rem;
  border-radius: 1rem;
  margin-bottom: 0;
}

p {
  margin-top: 0.5rem
}
</style>
