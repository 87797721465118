<template>
  <div>
    <BaseError 
      title="Error while fetching the data quality score" 
      :message="error" 
      :error="error"
      :fixed="false"
      :isConfirmation="true"
      @close="closeErrorDialog"
    />
    <BaseCard>
      <h1>
        <FontAwesomeIcon icon="fa-solid fa-medal" /> Data Quality Score Report
      </h1>
      <BaseSpinner v-if="status === 'IN PROGRESS'" message="Calculating quality score, it might take a while ..."/>
      <div v-else>
        <QualityScoreReportHeader 
        :overallScore="qualityScore.overallScore" 
        :regulationName="regulation.name" 
        :tableName="regulation.associatedTable"
        />
        <BaseCard>
          <h2>
            <FontAwesomeIcon icon="fa-solid fa-table-columns" /> Score by field
          </h2>
          <h3>
              <FontAwesomeIcon icon="fa-solid fa-square-poll-vertical" /> Results
          </h3>
          <ScoresBar :scoreList="qualityScore.columnWiseScore"/>
        </BaseCard>
        <BaseCard>
          <h2><FontAwesomeIcon icon="fa-solid fa-section" /> Score by pattern</h2>
          <BaseCard 
            v-for="patternWiseScore in patternWiseScores" 
            :key="patternWiseScore.score.name"
          >
            <h3>{{ patternWiseScore.pattern.name}}</h3>
            <h5>{{ patternWiseScore.pattern.description}}</h5>
            <KindTag :kind="patternWiseScore.pattern.kind"/>
            <ExpectationTag :kind="patternWiseScore.pattern.kind" :expectation="patternWiseScore.pattern.expectation"/>
            <h3>
              <FontAwesomeIcon icon="fa-solid fa-square-poll-vertical" /> Results
            </h3>
            <ScoresBar :scoreList="patternWiseScore.score.columnScores"/>
          </BaseCard>
        </BaseCard>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import ScoresBar from '@/components/quality-scores/ScoresBar.vue'
import QualityScoreReportHeader from '@/components/quality-scores/QualityScoreReportHeader.vue'
import KindTag from '@/components/patterns/KindTag.vue'
import ExpectationTag from '@/components/patterns/ExpectationTag.vue'

export default {
  props: ['id', 'appName'],
  components: {
    ScoresBar,
    QualityScoreReportHeader,
    KindTag,
    ExpectationTag
  },
  data() {
    return {
      error: null
    }
  },
  computed: {
    qualityScore() {
      return this.$store.getters['quality-scores/qualityScore']
    },
    regulation() {
      return this.$store.getters['regulations/regulation']
    },
    status() { 
      const job = this.$store.getters['quality-scores/jobByRegulationId'](parseInt(this.id))
      if (!job) {
        return 'NOT STARTED'
      }
      return job.status
    },
    patternWiseScores() {
      const qualityScore = this.$store.getters['quality-scores/qualityScore']
      const regulation = this.$store.getters['regulations/regulation']
      if (!Object.keys(qualityScore).length || !Object.keys(regulation).length) {
        return []
      }
      const patternWiseScores = []
      for (let i = 0; i < regulation.patterns.length; i++) {
        patternWiseScores.push({
          pattern: regulation.patterns[i],
          score: qualityScore.patternWiseScore[i]
        })
      }
      return patternWiseScores
    }
  },
  methods: {
    closeErrorDialog() {
      if (this.error) {
        this.$router.replace(`/regulations/${this.id}`)
      } else {
        this.error = null
      }
    }
  },
  beforeMount() {
    const job = this.$store.getters['quality-scores/jobByRegulationId'](parseInt(this.id))
    if (!job) {
      this.$router.replace('/regulations')
    }
  },
  async created() {
    const job = this.$store.getters['quality-scores/jobByRegulationId'](parseInt(this.id))
    if (job.appName) {
      try {
        await this.$store.dispatch('quality-scores/loadQualityScore', { appName: job.appName })
        await this.$store.dispatch('regulations/loadRegulationById', { id: this.id })
      } catch (err) {
        this.error = err.message
      }
    }
  }
}
</script>

