export default {
  patterns(state) {
    return state.patterns
  },
  patternById(state) {
    state
    return patternId => {
      return state.patterns.find(pattern => pattern.id === patternId)
    }
  }
}