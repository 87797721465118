export default {
  setAppName(state, payload) {
    state.appName = payload
  },
  setQualityScore(state, payload) {
    state.qualityScore = payload
  },
  addJob(state, payload) {
    const job = state.jobs.find(job => job.regulationId === payload.regulationId)
    if (!job) {
      state.jobs.push(payload)
    } else {
      job.appName = payload.appName
      job.status = payload.status
    }
  },
  updateJobStatus(state, payload) {
    const job = state.jobs.find(job => job.appName === payload.appName)
    if (job) {
      job.status = payload.status
    }
  }
}