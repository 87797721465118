<template>
  <div class="expectation-container">
    <input 
      type="number"
      step="any"
      name="lower-bound" 
      id="lower-bound" 
      :value="lowerBound" 
      @input="$emit('update:lowerBound', $event.target.value)"
    >
    <p>&le; Values &le;</p>
    <input 
      type="number" 
      step="any"
      name="upper-bound" 
      id="upper-bound" 
      :value="upperBound" 
      @input="$emit('update:upperBound', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  props: ['lowerBound', 'upperBound'],
  emits: ['update:lowerBound', 'update:upperBound']
}
</script>

<style scoped>
#lower-bound, #upper-bound {
  width: 20%;
  height: 50%;
  margin-top: 0;
  margin-bottom: auto;
}

input {
  border-radius: 0.5rem;
}

#lower-bound {
  margin-right: 0.5rem;
}

#upper-bound {
  margin-left: 0.5rem;
}

.expectation-container {
  display: flex;
}

p {
  margin-top: 0;
}
</style>