export default {
  tables(state) {
    return state.tables
  },
  tableUriByName(state) {
    return tableName => {
      const table = state.tables.filter(table => table.name === tableName)[0]
      if (table) {
        return table.uri
      }
    }
  }
}