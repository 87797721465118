<template>
  <div>
    <BaseError 
      title="Error while adding the pattern to the regulation" 
      :message="error" 
      :error="error"
      :fixed="false"
      :isConfirmation="true"
      @close="closeErrorDialog"
    />
    <BaseCard>
      <h1>
        <FontAwesomeIcon icon="fa-solid fa-section" /> Add Pattern to Regulation
      </h1>
      <BaseSpinner v-if="isLoading" message="Loading..."/>
      <div v-else>
        <h4>
          {{$route.query.patternName}}
        </h4>
        <p>{{$route.query.patternDescription}}</p>
        <form @submit.prevent="addPatternToRegulation">
          <div class="form-control" :class="{invalid: !regulationSelectedIsValid}">
            <label for="name">Regulation</label>
            <select
              name="regulation" id="regulation" v-model="regulationSelected">
              <option 
                v-for="regulation in allRegulations" 
                :key="regulation.id" 
                :value="regulation"
              >
                {{ regulation.name }}
              </option>
            </select>
            <p v-if="!regulationSelectedIsValid">Please select a regulation.</p>
          </div>
          <transition name="show-affected-columns">
            <div
              v-if="showAffectedColumnsForm"
              :key="regulationSelected"
              class="form-control" 
              :class="{invalid: showErrorsOnAffectedColumnsForm}"
            >
              <label for="checkbox">Affected Columns</label>
              <div 
                v-for="column in allColumns" 
                :key="column" class="checkbox-item"
              >
                <input
                  type="checkbox" 
                  :id="column" 
                  :value="column" 
                  v-model="affectedColumns"
                >
                <label style="overflow: auto;" :for="column">{{ column }}</label>
              </div>
              <p v-if="showErrorsOnAffectedColumnsForm">Choose at least one column.</p>
            </div>
          </transition>
          <div class="controls">
            <BaseButton>Add</BaseButton>
          </div>
        </form>
      </div>
    </BaseCard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      regulationSelected: null,
      regulationSelectedIsValid: true,
      affectedColumns: [],
      affectedColumnsIsValid: true,
      formIsValid: true,
      error: null,
      fetchingError: false,
      numberOfRequests: 0,
      isLoading: false
    }
  },
  computed: {
    allRegulations() {
      return this.$store.getters['regulations/regulations']
    },
    allColumns() {
      const associatedTable = this.regulationSelected ? this.regulationSelected.associatedTable : null
      const table = this.$store.getters['tables/tables'].find(table => table.name === associatedTable)
      if (!table) {
        return null
      }
      return table.columns
    },
    showAffectedColumnsForm() {
      return !!this.regulationSelected
    },
    showErrorsOnAffectedColumnsForm() {
      return !this.affectedColumnsIsValid && this.regulationSelectedIsValid
    }
  },
  methods: {
    validateForm() {
      if (!this.regulationSelected) {
        this.regulationSelectedIsValid = false
      }
      if (this.affectedColumns.length === 0) {
        this.affectedColumnsIsValid = false
      }
      this.formIsValid = this.regulationSelectedIsValid && this.affectedColumnsIsValid
    },
    clearErrors() {
      this.regulationSelectedIsValid = true
      this.affectedColumnsIsValid = true
      this.formIsValid = true
    },
    async addPatternToRegulation() {
      this.isLoading = true
      this.numberOfRequests++
      if (this.numberOfRequests > 1) {
        this.isLoading = false
        this.numberOfRequests = 0
        return
      }
      this.clearErrors()
      this.validateForm()

      if (this.formIsValid) {
        const patternId = parseInt(this.$route.query.patternId)
        
        const pattern = this.$store.getters['patterns/patternById'](patternId)
        if (!pattern) {
          this.error = 'Pattern not found.'
          this.numberOfRequests = 0
          this.isLoading = false
          return
        }

        const regulation = this.$store.getters['regulations/regulation']
        if (!Object.keys(regulation).length) {
          try {
            await this.$store.dispatch('regulations/loadRegulationById', {
              id: this.regulationSelected.id
            })
          } catch (err) {
            this.error = err.message
            this.numberOfRequests = 0
            this.isLoading = false
            return
          }
        }

        try {
          await this.$store.dispatch('regulations/addPattern', {
            regulationId: this.regulationSelected.id,
            pattern: pattern,
            affectedColumns: this.affectedColumns
          })
          this.$router.push('/regulations/' + this.regulationSelected.id)
        } catch (err) {
          this.error = err.message
          this.numberOfRequests = 0
          this.isLoading = false
          return
        }
      } else {
        this.numberOfRequests = 0
      }
      this.isLoading = false
    },
    closeErrorDialog() {
      if (this.fetchingError) {
        this.$router.replace('/patterns')
      } else {
        this.error = null
      }
    },
  },
  async created() {
    this.isLoading = true
    const regulations = this.$store.getters['regulations/regulations']
    if (regulations.length === 0) {
      try {
        await this.$store.dispatch('regulations/loadRegulations')
      } catch (err) {
        this.error = 'Could not fetch the regulations.'
        this.fetchingError = true
      }
    }
    this.isLoading = false
  }
}
</script>

<style scoped>
h4 {
  background-color: rgb(245, 245, 245);
  width: fit-content;
  padding: 0.5rem;
  border-radius: 1rem;
  margin-bottom: 0;
}

p {
  margin-top: 0.5rem
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input, select {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  background-color: white;
  border-radius: 0.2rem;
}

input[type='checkbox'] {
  display: inline;
  width: auto;
  margin-right: 0.5rem;
  accent-color: rgb(127, 66, 245);
}

.checkbox-item {
  display: flex;
}

.invalid label, .invalid p {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}

.controls {
  text-align: right;
}

.show-affected-columns-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.show-affected-columns-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.show-affected-columns-enter-active {
  transition: all 0.3s ease-out;
}

.show-affected-columns-leave-active {
  transition: all 0.3s ease-in;
}

.show-affected-columns-enter-to,
.show-affected-columns-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
