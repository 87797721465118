import httpClient from "@/data/http-client"

let retries

export default {
  async applyRegulation(context, data) {
    const body = {
      regulation_id: data.id,
      table_uri: data.uri,
      val_table_storage_loc: data.uri + '_processed'
    }
    const response = await httpClient('api/quality-scores/jobs', {
      method: 'POST',
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      throw Error('Error while creating the data quality job')
    }

    const responseData = await response.json()

    context.commit('addJob', {
      appName: responseData.app_name,
      regulationId: data.id,
      status: 'IN PROGRESS'
    })
  },
  async loadQualityScore(context, data) {
    retries = setInterval(
      async () => {
        const response = await httpClient(`api/quality-scores/${data.appName}`)

        if (!response.ok) {
          clearInterval(retries)
          throw new Error('Error while fetching the data quality score.')
        }

        if (response.status === 202) {
          context.commit('updateJobStatus', {
            appName: data.appName,
            status: 'IN PROGRESS'
          })
        } else {
          clearInterval(retries)
          if (response.status !== 200) {
            throw new Error('Error while calculating the quality score. Please try again later.')
          }
          let responseData = await response.json()
          responseData = responseData.quality_score
          context.commit('updateJobStatus', {
            appName: data.appName,
            status: 'COMPLETED'
          })
          
          const qualityScore = {
            id: responseData.id,
            regulationId: responseData.regulation_id,
            overallScore: responseData.overall_score,
            columnWiseScore: [],
            patternWiseScore: []
          }
          const columnWiseScore = []
          for (const key in responseData.column_wise_score) {
            columnWiseScore.push({
              ...responseData.column_wise_score[key]
            })
          }

          qualityScore.columnWiseScore = columnWiseScore

          const patternWiseScore = []
          for (const key in responseData.pattern_wise_score) {
            patternWiseScore.push({
              name: responseData.pattern_wise_score[key].name,
              columnScores: responseData.pattern_wise_score[key].column_scores
            })
          }
          qualityScore.patternWiseScore = patternWiseScore

          context.commit('setQualityScore', qualityScore)
        }
      }, 15000
    )
  }
}