<template>
  <div class="expectation-container">
    <p>Values must belong to: </p>
    <textarea 
      name="values" 
      id="values" 
      rows="10" 
      :value="values" 
      @input="$emit('update:values', $event.target.value)">
    </textarea>
  </div>
</template>

<script>
export default {
  props: ['values']
}
</script>

<style scoped>
.expectation-container {
  display: block;
}

textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  background-color: white;
  border-radius: 0.5rem;
}

p {
  margin-top: 0;
}
</style>