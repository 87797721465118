<template>
  <button v-if="!link" :class="mode">
    <slot></slot>
  </button>
  <router-link :to="to" v-else :class="mode">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,
      default: '/'
    }
  }
}
</script>

<style scoped>
button,
a {
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font: inherit;
  background-color: rgb(127, 66, 245);
  border: 1px solid rgb(127, 66, 245);
  color: white;
  cursor: pointer;
  border-radius: 30px;
  margin-right: 0.5rem;
  display: inline-block;
  font-weight: 1000;
}

a:hover,
button:hover {
  background-color: white;
  color: rgb(127, 66, 245);
  border-color: rgb(127, 66, 245);
  transition: 0.3s;
}

a:active,
button:active {
  background-color: rgb(127, 66, 245);
  color: white;
  border-color: rgb(127, 66, 245);
}

</style>