import httpClient from '@/data/http-client'

export default {
  async loadPatterns(context) {
    const response = await httpClient('api/patterns/')
    const responseData = await response.json()

    if(!response.ok) {
      throw new Error(responseData.error)
    }

    const patterns = []
    for (const key in responseData.patterns) {
      const pattern = {
        id: responseData.patterns[key].id,
        name: responseData.patterns[key].name,
        description: responseData.patterns[key].description,
        kind: responseData.patterns[key].kind,
        expectation: responseData.patterns[key].expectation
      }
      patterns.push(pattern)
    }

    context.commit('setPatterns', patterns)
  },
  async registerPattern(context, data) {
    const body = {
      name: data.name,
      description: data.description,
      kind: data.kind,
      expectation: data.expectation
    }
    const response = await httpClient('api/patterns/', {
      method: 'POST',
      body: JSON.stringify(body)
    })
    const responseData = await response.json()

    if (!response.ok) {
      if (response.status === 400) {
        throw new Error(responseData.error)
      } else if (response.status === 409) {
        throw new Error('Pattern name is already in use, please choose another one.')
      } else {
        throw new Error('Failed to register the pattern, please try again later.')
      }
    }

    const payload = {
      id: responseData.pattern.id,
      name: responseData.pattern.name,
      kind: responseData.pattern.description,
      expectation: responseData.pattern.expectation
    }
    
    context.commit('registerPattern', payload)
  },
  async deletePattern(context, data) {
    const response = await httpClient(`api/patterns/${data.id}`, {
      method: 'DELETE'
    })

    if(!response.ok) {
      if (response.status === 404) {
        throw new Error('Pattern does not exist')
      } else {
        throw new Error('Failed to delete the pattern')
      }
    }
    context.commit('deletePattern', data)
  },
  async updatePattern(context, data) {
    let body
    if (data.name) {
      body = {
        name: data.name,
        description: data.description,
        expectation: data.expectation
      }
    } else {
      body = {
        description: data.description,
        expectation: data.expectation
      }
    }
    const response = await httpClient(`api/patterns/${data.id}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      const responseData = await response.json()
      if (response.status === 400) {
        throw new Error(responseData.error)
      } else if (response.status == 404) {
        throw new Error('Pattern does not exist.')
      }else if (response.status === 409) {
        throw new Error('Pattern name is already in use, please choose another one.')
      } else {
        throw new Error('Failed to register the pattern, please try again later.')
      }
    }

    context.commit('updatePattern', data)
  }
}