<template>
   <div class="expectation-container">
      <p>Values Z-Score &le;</p>
      <input 
        type="number" 
        name="threshold" 
        id="threshold" 
        :value="threshold" 
        @input="$emit('update:threshold', $event.target.value)"
      >
    </div>
</template>

<script>
export default {
  props: ['threshold']
}
</script>

<style scoped>
#threshold {
  width: 20%;
  height: 50%;
  margin-top: 0;
  margin-bottom: auto;
  margin-left: 0.5rem;
}

input {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  background-color: white;
  border-radius: 0.5rem;
}

.expectation-container {
  display: flex;
}

p {
  margin-top: 0;
}
</style>