<template>
  <div>
    <BaseError 
      title="Error while updating the regulation" 
      :message="error" 
      :error="error"
      :fixed="false"
      :isConfirmation="true"
      @close="closeErrorDialog"
    />
    <BaseCard v-if="!fetchingError">
      <h1>
        <FontAwesomeIcon icon="fa-solid fa-file-contract" /> Update Regulation
      </h1>
      <BaseSpinner v-if="isLoading" message="Loading..."/>
      <form v-else @submit.prevent="registerRegulation">
        <div class="form-control" :class="{invalid: !nameIsValid}">
          <label for="name">Name</label>
          <input 
            type="text" 
            name="name" 
            id="name" 
            v-model.trim="name"
          >
          <p v-if="!nameIsValid">This field cannot be empty, please enter a valid name.</p>
        </div>
        <div class="form-control">
          <label for="description">Description</label>
          <textarea 
            type="textarea" 
            name="description" 
            id="description" 
            rows="3" 
            v-model.trim="description">
          </textarea>
        </div>
        <div class="controls">
          <BaseButton @click="updateRegulation">Update</BaseButton>
        </div>
      </form>
    </BaseCard>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      name: '',
      description: '',
      nameIsValid: true,
      validForm: true,
      error: null,
      numberOfRequests: 0,
      initialName: '',
      fetchingError: false,
      isLoading: false,
    }
  },
  computed: {
    nameChanged() {
      return this.name !== this.initialName
    }
  },
  methods: {
    validateForm() {
      if (this.name === '') {
        this.nameIsValid = false
      }
      this.validForm = this.nameIsValid
    },
    clearErrors() {
      this.nameIsValid = true
      this.validForm = true
    },
    async updateRegulation() {
      this.isLoading = true
      this.numberOfRequests++
      if (this.numberOfRequests > 1) {
        this.nameChanged = false
        this.isLoading = false
        this.numberOfRequests = 0
        return
      }
      this.clearErrors()
      this.validateForm()

      if (this.validForm) {
        try {
          await this.$store.dispatch('regulations/updateRegulation', {
            id: parseInt(this.id),
            name: this.nameChanged ? this.name : undefined,
            description: this.description,
          })
          this.isLoading = false
          this.$router.replace("/regulations")
        } catch (err) {
          this.error = err.message
          this.numberOfRequests = 0
        }
      } else {
        this.numberOfRequests = 0
      }
      this.nameChanged = false
      this.isLoading = false
    },
    closeErrorDialog() {
      if (this.fetchingError) {
        this.$router.replace('/regulations')
      } else {
        this.error = null
      }
    }
  },
  async created() {
    this.isLoading = true
    const regulations = this.$store.getters['regulations/regulations']
    if (regulations.length === 0) {
      try {
        await this.$store.dispatch('regulations/loadRegulations')
      } catch(err) {
        this.error = 'Could not fetch the regulations.'
        this.fetchingError = true
      }
    }
    const regulationId = parseInt(this.id)
    const regulation = this.$store.getters['regulations/regulationById'](regulationId)

    if (!regulation) {
      this.error = 'Could not fetch the regulation.'
      this.fetchingError = true
    } else {
      this.name = regulation.name
      this.initialName = regulation.name
      this.description = regulation.description
    }
    this.isLoading = false
  }
}
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input, textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  background-color: white;
  border-radius: 0.2rem;
}

.invalid label, .invalid p {
  color: red;
}

.invalid input {
  border: 1px solid red;
}

.controls {
  text-align: right;
}
</style>