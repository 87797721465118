<template>
  <BaseCard>
      <div class="dash-header">
        <div class="dash-header-container">
          <h2>Grade</h2>
          <div class="grade" :style="gradeColor">
            <FontAwesomeIcon :icon="gradeIcon" />
          </div>
        </div>
        <div class="dash-header-container">
          <h2>Score</h2>
          <h1 class="overall-score">{{ overallScoreFormatted }}</h1>
        </div>
        <div class="dash-header-container">
          <h3 class="regulation-tag">
            <FontAwesomeIcon icon="fa-solid fa-file-contract" /> {{ regulationName }}
          </h3>
          <TableTag :tableName="tableName"/>
        </div>
      </div>
    </BaseCard>
</template>

<script>
import TableTag from '../ui/TableTag.vue'

export default {
  components: {
    TableTag
  },
  props: ['overallScore', 'regulationName', 'tableName'],
  computed: {
    overallScoreFormatted() {
      return (this.overallScore * 100).toFixed(1) + '%'
    },
    gradeIcon() {
      if (this.overallScore >= 0.9) {
        return ['fas','a']
      } else if (this.overallScore < 0.9 && this.overallScore >= 0.7) {
        return ['fas','b']
      } else if (this.overallScore < 0.7 && this.overallScore >= 0.5) {
        return ['fas','c']
      } else if (this.overallScore < 0.5 && this.overallScore >= 0.3) {
        return ['fas','d']
      } else {
        return ['fas','f']
      }
    },
    gradeColor() {
      if (this.overallScore >= 0.9) {
        return {backgroundColor: 'rgb(102, 213, 156)'}
      } else if (this.overallScore >= 0.7 && this.overallScore < 0.9) {
        return {backgroundColor: 'rgb(255, 246, 124)'}
      } else if (this.overallScore > 0.5 && this.overallScore <= 0.7) {
        return {backgroundColor: 'rgb(255, 209, 111)'}
      } else {
        return {backgroundColor: 'rgb(255, 125, 125)'}
      }
    }
  }
}
</script>

<style scoped>
.grade {
  border-radius: 100%;
  font-size: 45px;
  padding: 1rem 1.7rem 1rem 1.7rem;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}

.dash-header-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  text-align: center;
  align-items: top;
}

.overall-score {
  padding-top: 0;
  margin-top: 2rem;
  margin-bottom: 0;
  font-size: 45px;
}

.regulation-tag {
  border-radius: 2rem;
  padding: 0.5rem;
}

.dash-header {
  display: flex;
}
</style>