<template>
  <h3>
    <FontAwesomeIcon icon="fa-solid fa-table" /> {{ tableName }}
  </h3>
</template>

<script>
export default {
  props: ['tableName']
}
</script>

<style scoped>
h3 {
  background-color: #006100;
  color: white;
  border-radius: 1rem;
  margin-top: auto;
  padding: 0.5rem;
  width: fit-content;
}
</style>