<template>
  <BaseDialog 
    :show="!!error"
    :fixed="fixed"
  >
    <template #header>
      <h2 class="error-title">{{ title }}</h2>
    </template>
    <div class="error-container">
      <h1 class="error-icon"><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /></h1>
      <p class="message">{{ message }}</p>
    </div>
    <template v-if="isConfirmation" #actions>
      <BaseButton @click="$emit('close')">Ok</BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
export default {
  props: ['title', 'message', 'error', 'fixed', 'isConfirmation']
}
</script>

<style scoped>
.error-title {
  margin: 0;
}

.error-container {
  text-align: center;
  margin-bottom: 0px;
}

.error-icon {
  font-size: 75px; 
  margin: 0; 
  color: rgb(255, 125, 125);
}

.message {
  margin-bottom: 0;
}
</style>