<template>
  <BaseDialog 
    :show="show" 
    :title="title" 
    @close="$emit('close')" 
    @continue="$emit('continue')"
  >
    <BaseSpinner v-if="showLoading" :message="loadingMessage"/>
    <div v-else class="warning-container">
      <h1 class="warning-icon"><FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" /></h1>
      <p class="message">{{ message }}</p>
    </div>
  </BaseDialog>
</template>

<script>
export default {
  props: ['show', 'title', 'message', 'showLoading', 'loadingMessage']
}
</script>

<style scoped>
.warning-container {
  text-align: center;
  margin-bottom: 0px;
}

.warning-icon {
  font-size: 75px; 
  margin: 0; 
  color: rgb(255, 209, 111);
}

.message {
  margin-bottom: 0;
}
</style>