import { createStore } from 'vuex'

import patterns from './modules/patterns'
import qualityScores from './modules/quality-scores'
import regulations from './modules/regulations'
import tables from './modules/tables'


export default createStore({
  modules: {
    patterns,
    regulations,
    tables,
    'quality-scores': qualityScores
  }
})