<template>
  <li>
    <div class="table-name-header">
      <h2>{{ table.name }}</h2>
      <div class="btn-wrapper">
        <BaseButton @click="toggleTable">
          <div v-if="!show">
            <FontAwesomeIcon icon="fa-solid fa-eye" /> View
          </div>
          <div v-else>
            <FontAwesomeIcon icon="fa-solid fa-eye-slash" /> Hide
          </div>
        </BaseButton>
      </div>
    </div>
    <transition name="show-table">
      <table v-if="show">
        <thead>
          <tr>
            <th v-for="column in table.columns" :key="column">
              <p>{{ column }}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in table.samples" :key="row.id">
            <td v-for="content in row.content" :key="content">{{ content }}</td>
          </tr>
        </tbody>
      </table>
    </transition>
    <div class="card-footer">
      <h4><FontAwesomeIcon icon="fa-solid fa-table-cells" /> Rows count: {{ table.numberOfRows }}</h4>
      <h4 style="margin-left: 2rem;"><FontAwesomeIcon icon="fa-solid fa-table-columns" /> Columns count: {{ table.columns.length }}</h4>
    </div>
  </li>
</template>

<script>
export default {
  props: ['table'],
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleTable() {
      this.show = !this.show
    }
  }
}
</script>

<style scoped>
table {
  margin-top: 1rem;
  table-layout: fixed;
  display: block;
  overflow-x: auto;
}

th, td {
  padding: 5px 4px 6px 4px; 
  text-align: left;
  vertical-align: top;
  overflow: hidden;
  max-width: 10rem;
  min-width: 10rem;
}

td {
  border-top: 1px solid #ddd;    
}

thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
  border-radius: 0.2rem 0 0 0;
}

thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 0.2rem;
}

tr:nth-child(even) {
    background-color: rgb(246, 246, 246);
}

th {
  background-color: rgb(230, 230, 230);
}

li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 0.2rem;
  padding: 0 1rem;
}

p {
  margin: 0;
}

.card-footer {
  display: flex;
}

.table-name-header {
  display: flex;
  justify-content: space-between;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-table-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.show-table-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.show-table-enter-active {
  transition: all 0.3s ease-out;
}

.show-table-leave-active {
  transition: all 0.3s ease-in;
}

.show-table-enter-to,
.show-table-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>