<template>
  <BaseCard>
    <h2><FontAwesomeIcon icon="fa-solid fa-table" /> Tables</h2>
    <ul>
      <TableItem v-for="table in tables" :key="table.id" :table="table"/>
    </ul>
  </BaseCard>
</template>

<script>
import TableItem from "../components/tables/TableItem.vue"

export default {
  components: {
    TableItem
  },
  computed: {
    tables() {
      return this.$store.getters["tables/tables"]
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h2 {
  margin-bottom: 0;
}

.controls {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding: auto;
}

.card-header {
  display: flex;
  margin-top: 0.5rem;
}
</style>