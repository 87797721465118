<template>
  <h3 class="pattern-kind">
    <FontAwesomeIcon icon="fa-solid fa-bookmark" /> {{ kindFormatted }}
  </h3>
</template>

<script>
export default {
  props: ['kind'],
  computed: {
    kindFormatted() {
      return !this.kind ? '' : this.kind.split('-')
      .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ')
    }
  }
}
</script>

<style scoped>
.pattern-kind {
  background-color: rgb(51, 255, 153);
  border-radius: 1rem;
  margin-right: auto;
  margin-top: auto;
  padding: 0.5rem;
  text-overflow: clip;
  width: fit-content;
}
</style>