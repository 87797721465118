<template>
  <div>
    <BaseError 
      :title="errorTitle"
      :message="error" 
      :error="error"
      :fixed="true"
      :isConfirmation="false"
    />
    <BaseCard v-if="!error">
      <BaseSpinner v-if="isLoading" message="Loading..."/>
      <div v-else>
        <h1 style="overflow: auto;">{{ regulation.name }}</h1>
        <div class="regulation-header">
          <div class="btn-wrapper">
            <BaseButton v-if="(status === 'NOT STARTED' || status === 'COMPLETED') && hasPatterns" @click="applyRegulation">
              <FontAwesomeIcon icon="fa-solid fa-play" /> Apply
            </BaseButton>
            <BaseButton v-if="status === 'IN PROGRESS' || status === 'COMPLETED'" link :to="linkToReport">
              <FontAwesomeIcon icon="fa-solid fa-square-poll-vertical" /> View Results
            </BaseButton>
            <BaseButton link to="/patterns">
              <FontAwesomeIcon icon="fa-solid fa-plus" /> New Pattern
            </BaseButton>
          </div>
        </div>
        <h4 class="overflow">{{ regulation.description }}</h4>
        <TableTag :tableName="regulation.associatedTable"/>
        <transition-group tag="ul" name="pattern-list">
          <PatternItem 
            v-for="pattern in regulation.patterns" 
            :key="pattern.id" 
            :pattern="pattern"
            :fromRegulation="{id: this.id}"   
          />
        </transition-group>
        <p v-if="!hasPatterns">
          It seems that your regulation does not have any patterns attached to it, add some before applying it!
        </p>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import TableTag from '@/components/ui/TableTag.vue'
import PatternItem from '@/components/patterns/PatternItem.vue'

export default {
  props: ['id'],
  components: {
    PatternItem, TableTag
  },
  data() {
    return {
      showApplyForm: false,
      error: null,
      errorTitle: 'Error while fetching the regulation',
      isLoading: false
    }
  },
  computed: {
    regulation() {
      return this.$store.getters['regulations/regulation']
    },
    status() {
      const job = this.$store.getters['quality-scores/jobByRegulationId'](parseInt(this.id))
      if (!job) {
        return 'NOT STARTED'
      }
      return job.status
    },
    hasPatterns() {
      const regulation = this.$store.getters['regulations/regulation']
      const patterns = regulation.patterns
      return patterns && patterns.length > 0
    },
    linkToReport() {
      const job = this.$store.getters['quality-scores/jobByRegulationId'](parseInt(this.id))
      return `/regulations/${job.regulationId}/scores/${job.appName}`
    }
  },
  methods: {
    async applyRegulation() {
      this.isLoading = true
      const uri = this.$store.getters['tables/tableUriByName'](this.regulation.associatedTable)
      try {
        await this.$store.dispatch('quality-scores/applyRegulation', {
          id: parseInt(this.id),
          uri: uri
        })
      } catch (err) {
        this.errorTitle = 'Failed to start data quality score calculation job'
        this.error = err.message
      }
      this.isLoading = false
    },
    closeErrorDialog() {
      if (this.error) {
        this.$router.replace('/regulations')
      } else {
        this.error = null
        this.errorTitle = 'Error while fetching the regulation'
      }
    }
  },
  async created() {
    this.isLoading = true
    const regulation = this.$store.getters['regulations/regulation']
    if (!Object.keys(regulation).length || regulation.id !== this.id) {
      try {
        await this.$store.dispatch('regulations/loadRegulationById', {
          id: this.id
        })
      } catch (err) {
        this.errorTitle = 'Error while fetching the regulation'
        this.error = err.message
      }
    }
    this.isLoading = false
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  margin-bottom: 0;
}

.regulation-header {
  display: flex;
  margin-top: 0.5rem;
}

.btn-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}

.pattern-list-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.pattern-list-leave-active {
  transition: all 1s ease-in;
  position: absolute;
}

.pattern-list-leave-to {
  opacity: 0;
  transform: translateX(60px);
}

.pattern-list-move {
  transition: transform 1s ease;
}

.overflow {
  overflow: auto;
}
</style>