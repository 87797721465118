<template>
  <div>
    <BaseError 
      title="Error while updating the pattern" 
      :message="error" 
      :error="error"
      :fixed="false"
      :isConfirmation="true"
      @close="closeErrorDialog"
    />
    <BaseCard>
      <h1>
        <FontAwesomeIcon icon="fa-solid fa-section" /> Update Pattern
      </h1>
      <BaseSpinner v-if="isLoading" message="Loading pattern..."/>
      <form v-else @submit.prevent="updatePattern">
        <div class="form-control" :class="{invalid: !nameIsValid}">
          <label for="name">Name</label>
          <input 
            type="text" 
            name="name" 
            id="name" 
            v-model.trim="name"
          />
          <p v-if="!nameIsValid">This field cannot be empty, please enter a valid name.</p>
        </div>
        <div class="form-control">
          <label for="description">Description</label>
          <textarea 
            type="textarea" 
            name="description" 
            id="description" 
            rows="3" 
            v-model.trim="description">
          </textarea>
        </div>
        <div v-if="showExpectation" class="form-control" :class="{invalid: !expectationIsValid}">
          <label for="expectation">Expectation</label>
          <IsBetweenExpectation 
            v-if="kind==='is-between'" 
            v-model:lowerBound="expectation.lowerBoundValue" 
            v-model:upperBound="expectation.upperBoundValue"
          />
          <IsGreaterOrEqualThanExpectation
            v-else-if="kind==='is-greater-or-equal-than'"
            v-model:lowerBound="expectation.lowerBoundValue"
          />
          <IsGreaterThanExpectation
            v-else-if="kind==='is-greater-than'"
            v-model:lowerBound="expectation.lowerBoundValue"
          />
          <IsLessOrEqualThanExpectation
            v-else-if="kind==='is-less-or-equal-than'"
            v-model:upperBound="expectation.upperBoundValue"
          />
          <IsLessThanExpectation
            v-else-if="kind==='is-less-than'"
            v-model:upperBound="expectation.upperBoundValue"
          />
          <IsInExpectation 
            v-else-if="kind==='is-in'" 
            v-model:values="expectation.values"
          />
          <MatchesRegexExpectation 
            v-else-if="kind==='matches-regex'" 
            v-model:match="expectation.match"
          />
          <IsNumericalAnomalyExpectation
            v-else-if="kind==='is-not-numerical-anomaly'"
            v-model:threshold="expectation.threshold"
          />
          <IsEqualExpectation
            v-else-if="kind==='is-equal'"
            v-model:match="expectation.match"
          />
          <IsDateTypeExpectation
            v-else-if="kind==='is-date-type'"
            v-model:match="expectation.match"
          />
          <IsNumericalTypeExpectation
            v-else-if="kind==='is-numerical-type'"
            v-model:decimals="expectation.decimals"
          />
          <p v-if="!expectationIsValid">Please set the expectation.</p>
        </div>
        <div class="controls">
          <BaseButton @click="updatePattern">Update</BaseButton>
        </div>
      </form>
    </BaseCard>
  </div>
</template>

<script>
import IsBetweenExpectation from '@/components/patterns/expectations/IsBetweenExpectation.vue'
import IsGreaterOrEqualThanExpectation from '@/components/patterns/expectations/IsGreaterOrEqualThanExpectation.vue'
import IsGreaterThanExpectation from '@/components/patterns/expectations/IsGreaterThanExpectation.vue'
import IsLessOrEqualThanExpectation from '@/components/patterns/expectations/IsLessOrEqualThanExpectation.vue'
import IsLessThanExpectation from '@/components/patterns/expectations/IsLessThanExpectation.vue'
import IsInExpectation from '@/components/patterns/expectations/IsInExpectation.vue'
import MatchesRegexExpectation from '@/components/patterns/expectations/MatchesRegexExpectation.vue'
import IsNumericalAnomalyExpectation from '@/components/patterns/expectations/IsNumericalAnomalyExpectation.vue'
import IsEqualExpectation from '@/components/patterns/expectations/IsEqualExpectation.vue'
import IsDateTypeExpectation from '@/components/patterns/expectations/IsDateTypeExpectation.vue'
import IsNumericalTypeExpectation from '@/components/patterns/expectations/IsNumericalTypeExpectation.vue'

export default {
  props: ['id'],
  components: {
    IsBetweenExpectation,
    IsGreaterOrEqualThanExpectation,
    IsGreaterThanExpectation,
    IsLessOrEqualThanExpectation,
    IsLessThanExpectation, 
    IsInExpectation,
    MatchesRegexExpectation,
    IsNumericalAnomalyExpectation,
    IsEqualExpectation,
    IsDateTypeExpectation,
    IsNumericalTypeExpectation
  },
  computed: {
    showExpectation() {
      return !!this.kind && [
        'is-between', 
        'is-greater-than', 
        'is-greater-or-equal-than', 
        'is-less-than', 
        'is-less-or-equal-than',
        'is-equal',
        'matches-regex',
        'is-in',
        'is-not-numerical-anomaly',
        'is-date-type',
        'is-numerical-type'
      ].includes(this.kind)
    },
    nameChanged() {
      return this.name !== this.initialName
    }
  },
  data() {
    return {
      name: '',
      nameIsValid: true,
      description: '',
      kind: null,
      expectation: {},
      expectationIsValid: true,
      validForm: true,
      error: null,
      numberOfRequests: 0,
      initialName: '',
      fetchingError: false,
      isLoading: false,
    }
  },
  methods: {
    validateForm() {
      if (this.name === '') {
        this.nameIsValid = false
      }
      this.expectationIsValid = this.validateExpectation()
      this.validForm = this.nameIsValid && this.expectationIsValid
    },
    clearErrors() {
      this.nameIsValid = true
      this.expectationIsValid = true
      this.validForm = true
    },
    async updatePattern() {
      this.isLoading = true
      this.numberOfRequests++
      if (this.numberOfRequests > 1) {
        this.numberOfRequests = 0
        this.nameChanged = false
        this.isLoading = false
        return
      }
      this.clearErrors()
      this.validateForm()
    
      if (this.validForm) {
        const parsedExpectation = this.parseExpectation()

        try {
          await this.$store.dispatch('patterns/updatePattern', {
            name: this.nameChanged ? this.name : undefined,
            description: this.description,
            expectation: parsedExpectation,
            id: this.id
          })
          this.$router.replace('/patterns')
        } catch (err) {
          this.error = err.message
          this.numberOfRequests = 0
        }
      } else {
        this.numberOfRequests = 0
      }
      this.nameChanged = false
      this.isLoading = false
    },
    closeErrorDialog() {
      if (this.fetchingError) {
        this.$router.replace('/patterns')
      } else {
        this.error = null
      }
    },
    validateExpectation() {
      if (this.kind === 'is-between') {
        return !!this.expectation.lowerBoundValue && !!this.expectation.upperBoundValue
      } else if (this.kind == 'is-greater-or-equal-than' || this.kind == 'is-greater-than') {
        return !!this.expectation.lowerBoundValue
      } else if (this.kind == 'is-less-or-equal-than' || this.kind == 'is-less-than') {
        return !!this.expectation.upperBoundValue
      } else if (this.kind === 'is-in') {
        return !!this.expectation.values && this.expectation.values.trim() !== ''
      } else if (this.kind === 'matches-regex' || this.kind === 'is-equal' || this.kind === 'is-date-type') {
        return !!this.expectation.match && this.expectation.match.trim() !== ''
      } else if (this.kind === 'is-not-numerical-anomaly') {
        return !!this.expectation.threshold
      } else if (this.kind === 'is-numerical-type') {
        return !!this.expectation.decimals && (this.expectation.decimals <= 20)
      } else {
        return true
      }
    },
    parseExpectation() {
      if (this.kind === 'is-between') {
        return [
          parseFloat(this.expectation.lowerBoundValue),
          parseFloat(this.expectation.upperBoundValue) 
        ]
      } else if (this.kind == 'is-greater-or-equal-than' || this.kind == 'is-greater-than') {
        return parseFloat(this.expectation.lowerBoundValue)
      } else if (this.kind == 'is-less-or-equal-than' || this.kind == 'is-less-than') {
        return parseFloat(this.expectation.upperBoundValue)
      } else if (this.kind === 'is-in') {
        return this.expectation.values.split('\n')
      } else if (this.kind === 'matches-regex' || this.kind === 'is-equal' || this.kind === 'is-date-type') {
        return this.expectation.match
      } else if (this.kind === 'is-not-numerical-anomaly') {
        return {
          kind: 'zscore', 
          params: {
            threshold: parseFloat(this.expectation.threshold)
          }
        }
      } else if (this.kind === 'is-numerical-type') {
        return parseInt(this.expectation.decimals)
      } else {
        return null
      }
    },
    setExpectation(kind, expectation) {
      if (kind === 'is-between') {
        return {
          lowerBoundValue: expectation[0],
          upperBoundValue: expectation[1]
        }
      } else if (kind == 'is-greater-or-equal-than' || kind == 'is-greater-than') {
        return {
          lowerBoundValue: expectation,
        }
      } else if (kind == 'is-less-or-equal-than' || this.kind == 'is-less-than') {
        return {
          upperBoundValue: expectation,
        }
      } else if (this.kind === 'is-in') {
        return {
          values: expectation.join('\n')
        }
      } else if (this.kind === 'matches-regex' || this.kind === 'is-equal' || this.kind === 'is-date-type') {
        return {
          match: expectation
        }
      } else if (this.kind === 'is-not-numerical-anomaly') {
        return {
          threshold: expectation.params.threshold
        }
      } else if (this.kind === 'is-numerical-type') {
        return {
          decimals: expectation
        }
      } else {
        return null
      }
    }
  },
  async created() {
    this.isLoading = true
    const patterns = this.$store.getters['patterns/patterns']
    if (patterns.length === 0) {
      try {
        await this.$store.dispatch('patterns/loadPatterns')
      } catch(err) {
        this.error = 'Could not fetch the patterns.'
        this.fetchingError = true
      }
    }

    const patternId = parseInt(this.id)
    const pattern = this.$store.getters['patterns/patternById'](patternId)
    if (!pattern) {
      this.error = "Could not fetch the pattern."
      this.fetchingError = true
    } else {
      this.name = pattern.name
      this.initialName = pattern.name
      this.description = pattern.description
      this.kind = pattern.kind
      this.expectation = this.setExpectation(this.kind, pattern.expectation)
    }
    this.isLoading = false
  }
}
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input, textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  background-color: white;
  border-radius: 0.2rem;
}

.invalid label, .invalid p {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}

.controls {
  text-align: right;
}
</style>