<template>
  <li>
    <BaseError 
      title="Error while deleting the regulation" 
      :message="error" 
      :error="error"
      :fixed="false"
      :isConfirmation="true"
      @close="closeErrorDialog"
    />
    <BaseDeleteConfirmation 
      :show="showDeleteConfirmationDialog"
      :showLoading="isLoading"
      loadingMessage="Loading..."
      title="Are you sure ?"
      message="Do you really want to delete this regulation ? The operation cannot be undone."
      @close="closeDeleteConfirmationDialog" 
      @continue="deleteRegulation"
    />
    <h2>{{ name }}</h2>
    <div class="card-header">
      <div class="controls">
        <BaseButton @click="viewRegulation">
          <FontAwesomeIcon icon="fa-solid fa-eye" /> View
        </BaseButton>
        <BaseButton @click="updateRegulation">
          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
        </BaseButton>
        <BaseButton @click="confirmRegulationDeletion">
          <FontAwesomeIcon icon="fa-solid fa-trash" /> Delete
        </BaseButton>
      </div>
    </div>
    <h4 class="overflow">{{ description }}</h4>
    <TableTag :tableName="associatedTable"/>
  </li>
</template>

<script>
import TableTag from '../ui/TableTag.vue'

export default {
  components: {
    TableTag
  },
  props: ['id', 'name', 'description', 'associatedTable'],
  data() {
    return {
      error: null,
      showDeleteConfirmationDialog: false,
      isLoading: false,
    }
  },
  methods: {
    async viewRegulation() {
      this.$router.push({
        path: `/regulations/${this.id}`
      })
    },
    async deleteRegulation() {
      this.isLoading = true
      try {
        await this.$store.dispatch('regulations/deleteRegulation', {
          id: this.id,
        })
      } catch (err) {
        this.showDeleteConfirmationDialog = false
        this.error = err.message
      }
      this.isLoading = false
    },
    updateRegulation() {
      this.$router.push({
        path: `/regulations/${this.id}/update`
      })
    },
    confirmRegulationDeletion() {
      this.showDeleteConfirmationDialog = true
    },
    closeDeleteConfirmationDialog() {
      this.showDeleteConfirmationDialog = false
    },
    closeErrorDialog() {
      this.error = null
    }
  }
}
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 0 1rem;
}

h2 {
  margin-bottom: 0;
}

.controls {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding: auto;
}

.card-header {
  display: flex;
  margin-top: 0.5rem;
}

.overflow {
  overflow: auto;
}
</style>