<template>
  <p class="affected-column">
    <FontAwesomeIcon icon="fa-solid fa-table-columns" /> {{ column }}
  </p>
</template>

<script>
export default {
  props: ['column']
}
</script>

<style scoped>
.affected-column {
  background-color: #498649;;
  color: white;
  border-radius: 1rem;
  margin-top: auto;
  padding: 0.5rem;
  width: fit-content;
  margin-right: 0.5rem;
  font-weight: 1000;
}
</style>