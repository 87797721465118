import { createRouter, createWebHistory } from 'vue-router'

import AddPatternToRegulation from './pages/patterns/AddPatternToRegulation'
import RegisterPattern from './pages/patterns/RegisterPattern.vue'
import RegisterRegulation from './pages/regulations/RegisterRegulation.vue'
import NotFound from './pages/NotFound.vue'
import PatternsList from './pages/patterns/PatternsList.vue'
import QualityScore from './pages/quality-scores/QualityScore.vue'
import RegulationDetail from './pages/regulations/RegulationDetail.vue'
import RegulationsList from './pages/regulations/RegulationsList.vue'
import TableList from './pages/TableList.vue'
import UpdatePattern from './pages/patterns/UpdatePattern.vue'
import UpdatePatternAttachedToRegulation from './pages/patterns/UpdatePatternAttachedToRegulation.vue'
import UpdateRegulation from './pages/regulations/UpdateRegulation.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/regulations'
    },
    {
      path: '/regulations',
      component: RegulationsList
    },
    {
      path: '/regulations/:id',
      component: RegulationDetail,
      props: true
    },
    {
      path: '/regulations/:id/scores/:appName',
      component: QualityScore,
      props: true
    },
    {
      path: '/regulations/register',
      component: RegisterRegulation
    },
    {
      path: '/regulations/:id/update',
      component: UpdateRegulation,
      props: true
    },
    {
      path: '/patterns',
      component: PatternsList
    },
    {
      path: '/patterns/register',
      component: RegisterPattern
    },
    {
      path: '/patterns/:id/update',
      component: UpdatePattern,
      props: true
    },
    {
      path: '/regulations/patterns/register',
      component: AddPatternToRegulation
    },
    {
      path: '/regulations/:regulationId/patterns/:patternId/update',
      component: UpdatePatternAttachedToRegulation,
      props: true
    },
    {
      path: '/tables',
      component: TableList
    },
    {
      path: '/:notFound(.*)',
      component: NotFound
    }
  ]
})

export default router