export default {
  regulations(state) {
    return state.regulations
  },
  regulation(state) {
    return state.regulation
  },
  regulationById(state) {
    return regulationId => {
      return state.regulations.find(regulation => regulation.id === regulationId)
    }
  },
  pattern(state) {
    return state.pattern
  }
}