<template>
  <div v-for="score in scoreList" :key="score.name" class="score">
    <ScoreBarItem :name="score.name" :score="score.score"/>
  </div>
</template>

<script>
import ScoreBarItem from './ScoreBarItem.vue'

export default {
  components: {
    ScoreBarItem
  },
  props: ['scoreList'],
}
</script>

<style scoped>
.score {
  display: grid;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  grid-template-columns: 1fr 10fr;
}
</style>