export default {
  setPatterns(state, payload) {
    state.patterns = payload
  },
  registerPattern(state, payload) {
    state.patterns.push(payload)
  },
  deletePattern(state, payload) {
    state.patterns = state.patterns.filter(pattern => pattern.id !== payload.id)
  },
  updatePattern(state, payload) {
    const index = state.patterns.findIndex(pattern => pattern.id == payload.id)
    state.patterns[index].name = payload.name
    state.patterns[index].description = payload.description
    state.patterns[index].expectation = payload.expectation
  }
}