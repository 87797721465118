<template>
  <div class="not-found-container">
      <h1 class="code">404</h1>
      <h2 class="message">Page Not Found</h2>
      <BaseButton link to="/regulations">
        <FontAwesomeIcon icon="fa-solid fa-home" /> Back Home
      </BaseButton>
  </div>
</template>

<style scoped>
.not-found-container {
  text-align: center;
  color: rgb(127, 66, 245);
}

.code {
  font-size: 200px;
  margin-bottom: 0;
}

.message {
  font-size: 45px;
  margin-top: 0;
}
</style>