<template>
  <div>
    <BaseError 
      title="Error while fetching data" 
      message="Could not establish communication with the server. Please try again later." 
      :error="error"
      :fixed="true"
      :isConfirmation="false"
    />
    <TheHeader></TheHeader>
    <transition name="route" mode="out-in" v-if="!error">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null
    }
  },
  async beforeCreate() {
    try {
      await this.$store.dispatch('regulations/loadRegulations')
      await this.$store.dispatch('patterns/loadPatterns')
    } catch(err) {
      this.error = err.message
    }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat");

* {
  box-sizing: border-box;
}

html {
  font-family: "Montserrat";
}

body {
  margin: 0;
  margin-top: 5%;
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (max-width: 700px) {
  body {
    margin-top: 50%;
  }
}

</style>