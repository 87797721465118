export default {
  qualityScore(state) {
    return state.qualityScore
  },
  appName(state) {
    return state.appName
  },
  status(state) {
    return state.status
  },
  jobByRegulationId(state) {
    return regulationId => state.jobs.filter(job => job.regulationId === regulationId)[0]
  }
} 