import httpClient from "@/data/http-client"

export default {
  async loadRegulations(context) {
    const response = await httpClient('api/regulations/')
    const responseData = await response.json()

    if(!response.ok) {
      throw new Error(responseData.error)
    }

    const regulations = []
    for (const key in responseData.regulations) {
      const regulation = {
        id: responseData.regulations[key].id,
        name: responseData.regulations[key].name,
        description: responseData.regulations[key].description,
        associatedTable: responseData.regulations[key].associated_table
      }
      regulations.push(regulation)
    }

    context.commit('setRegulations', regulations)
  },
  async registerRegulation(context, data) {
    const body = {
      name: data.name,
      description: data.description,
      associated_table: data.associatedTable
    }
    const response = await httpClient('api/regulations/', {
      method: 'POST',
      body: JSON.stringify(body)
    })
    const responseData = await response.json()

    if (!response.ok) {
      if (response.status === 400) {
        throw new Error(responseData.error)
      } else if (response.status === 409) {
        throw new Error('Regulation name is already in use, please choose another one.')
      } else {
        throw new Error('Failed to register the regulation, please try again later.')
      }
    }

    const payload = {
      id: responseData.regulation.id,
      name: responseData.regulation.name,
      description: responseData.regulation.description
    }
    context.commit('registerRegulation', payload)
  },
  async deleteRegulation(context, data) {
    const response = await httpClient(`api/regulations/${data.id}`, {
      method: 'DELETE'
    })

    if(!response.ok) {
      if (response.status === 404) {
        throw new Error('Regulation does not exist')
      } else {
        throw new Error('Failed to delete the regulation')
      }
    }

    context.commit('deleteRegulation', data)
  },
  async updateRegulation(context, data) {
    let body
    if (data.name) {
      body = {
        name: data.name,
        description: data.description
      }
    } else {
      body = {
        description: data.description
      }
    }
    const response = await httpClient(`api/regulations/${data.id}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      const responseData = await response.json()
      if (response.status === 400) {
        throw new Error(responseData.error)
      } else if (response.status == 404) {
        throw new Error('Regulation does not exist.')
      }else if (response.status === 409) {
        throw new Error('Regulation name is already in use, please choose another one.')
      } else {
        throw new Error('Failed to register the regulation, please try again later.')
      }
    }

    context.commit('updateRegulation', data)
  },
  async loadRegulationById(context, data) {
    const response = await httpClient(`api/regulations/${data.id}`)
    const responseData = await response.json()

    if (!response.ok) {
      throw new Error(responseData.error)
    }

    const patterns = []
    for (const key in responseData.regulation.patterns) {
      const pattern = {
        id: responseData.regulation.patterns[key].id,
        name: responseData.regulation.patterns[key].name,
        description: responseData.regulation.patterns[key].description,
        kind: responseData.regulation.patterns[key].kind,
        expectation: responseData.regulation.patterns[key].expectation,
        affectedColumns: responseData.regulation.patterns[key].affected_columns
      }
      patterns.push(pattern)
    }
    const regulation = {
      id: responseData.regulation.id,
      name: responseData.regulation.name,
      description: responseData.regulation.description,
      associatedTable: responseData.regulation.associated_table,
      patterns: patterns
    }
    context.commit('setRegulation', regulation)
  },
  async deletePattern(context, data) {
    const response = await httpClient(`api/regulations/${data.regulationId}/patterns/${data.patternId}`, {
      method: 'DELETE'
    })

    if(!response.ok) {
      if (response.status === 404) {
        throw new Error('Regulation or pattern does not exist.')
      } else {
        throw new Error('Failed to remove the pattern from the regulation.')
      }
    }

    context.commit('deletePattern', data)
  },
  async addPattern(context, data) {
    const body = {
      pattern_id: parseInt(data.pattern.id),
      regulation_id: parseInt(data.regulationId),
      affected_columns: data.affectedColumns
    }
    
    const response = await httpClient('api/regulations/patterns', {
      method: 'POST',
      body: JSON.stringify(body)
    })
    const responseData = await response.json()
    if (!response.ok) {
      throw new Error(responseData.error)
    }

    const patternAttachedToRegulation = data.pattern
    patternAttachedToRegulation.affectedColumns = data.affectedColumns
    context.commit('addPattern', {
      pattern: patternAttachedToRegulation
    })
  },
  async updatePattern(context, data) {
    const body = {
      affected_columns: data.affectedColumns
    }
    const response = await httpClient(`api/regulations/${data.regulationId}/patterns/${data.patternId}`, {
      method: 'PUT',
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      const responseData = await response.json()
      if (response.status === 400) {
        throw new Error(responseData.error)
      } else if (response.status === 404) {
        throw new Error('Either the regulation or the pattern do not exist.')
      } else {
        throw new Error('Error while updating the pattern. Please try again later')
      }
    }

    context.commit('updatePattern', {
      patternId: data.patternId,
      affectedColumns: data.affectedColumns
    })
  },
  async loadPattern(context, data) {
    const response = await httpClient(`api/regulations/${data.regulationId}/patterns/${data.patternId}`)
    
    if (!response.ok) {
      throw new Error('Error while fetching the pattern.')
    }

    const responseData = await response.json()

    const pattern = {
      id: responseData.pattern.id,
      name: responseData.pattern.name,
      description: responseData.pattern.description,
      kind: responseData.pattern.kind,
      expectation: responseData.pattern.expectation,
      affectedColumns: responseData.pattern.affected_columns
    }

    context.commit('setPattern', pattern)
  }
}