<template>
  <li>
    <BaseError 
      title="Error while deleting the pattern" 
      :message="error" 
      :error="error"
      :fixed="false"
      :isConfirmation="true"
      @close="closeErrorDialog"
    />
    <BaseDeleteConfirmation 
      :show="showDeleteConfirmationDialog"
      :showLoading="isLoading"
      loadingMessage="Loading..."
      title="Are you sure ?"
      message="Do you really want to delete this pattern ? The operation cannot be undone."
      @close="closeDeleteConfirmationDialog" 
      @continue="deletePattern"
    />
    <h2>{{ pattern.name }}</h2>
    <div class="card-header">
      <div class="controls">
        <BaseButton 
          v-if="!fromRegulation" 
          @click="addPatternToRegulation"
        >
          <FontAwesomeIcon icon="fa-solid fa-plus" /> Add to regulaton
        </BaseButton>
        <BaseButton 
          @click="updateRegulation"
        >
          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
        </BaseButton>
        <BaseButton 
          @click="confirmPatternDeletion"
        >
          <FontAwesomeIcon icon="fa-solid fa-trash" /> Delete
        </BaseButton>
      </div>
    </div>
    <h4 class="overflow">{{ pattern.description }}</h4>
    <KindTag :kind="pattern.kind"/>
    <ExpectationTag :kind="pattern.kind" :expectation="pattern.expectation"/>
    <div v-if="!!fromRegulation" class="affected-columns-container">
      <ColumnTag 
        v-for="affectedColumn in pattern.affectedColumns" 
        :key="affectedColumn" 
        :column="affectedColumn"
      />
    </div>
  </li>
</template>

<script>
import ColumnTag from '../ui/ColumnTag.vue'
import ExpectationTag from './ExpectationTag.vue'
import KindTag from './KindTag.vue'

export default {
  components: {
    KindTag, ExpectationTag, ColumnTag
  },
  props: ['pattern', 'fromRegulation'],
  data() {
    return {
      showDeleteConfirmationDialog: false,
      error: null,
      isLoading: false
    }
  },
  methods: {
    async deletePattern() {
      this.isLoading = true
      if (!this.fromRegulation) {
        try {
          await this.$store.dispatch('patterns/deletePattern', {
            id: this.pattern.id,
          })
        } catch (err) {
          this.showDeleteConfirmationDialog = false
          this.error = err.message
        }
      } else {
        try {
          await this.$store.dispatch('regulations/deletePattern', {
            patternId: this.pattern.id,
            regulationId: this.fromRegulation.id
          })
        } catch (err) {
          this.showDeleteConfirmationDialog = false
          this.error = err.message
        }
      }
      this.isLoading = false
    },
    updateRegulation() {
      if (!this.fromRegulation) {
        this.$router.push({
          path: `/patterns/${this.pattern.id}/update`, 
        })
      } else {
        this.$router.push({
          path: `/regulations/${this.fromRegulation.id}/patterns/${this.pattern.id}/update`
      })
      }
    },
    addPatternToRegulation() {
      this.$router.push({
        path: '/regulations/patterns/register', 
        query: {
          patternId: this.pattern.id,
          patternName: this.pattern.name,
          patternDescription: this.pattern.description
        }
      })
    },
    confirmPatternDeletion() {
      this.showDeleteConfirmationDialog = true
    },
    closeDeleteConfirmationDialog() {
      this.showDeleteConfirmationDialog = false
    },
    closeErrorDialog() {
      this.error = null
    }
  }
}
</script>

<style scoped>
h2 {
  text-overflow: clip;
  margin-bottom: 0;
}

li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 0 1rem;
}

.controls {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding: auto;
}

.card-header {
  display: flex;
  margin-top: 0.5rem;
}

.affected-columns-container {
  display: flex;
  flex-flow: wrap;
}

.overflow {
  overflow: auto;
}

</style>