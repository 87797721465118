<template>
  <div>
    <BaseError 
      title="Error while fetching the regulations" 
      :message="error" 
      :error="error"
      :fixed="true"
      :isConfirmation="false"
    />
    <BaseCard v-if="!error">
      <h2>
        <FontAwesomeIcon icon="fa-solid fa-file-contract" /> Regulations
      </h2>
      <BaseSpinner v-if="isLoading" message="Loading..."/>
      <div v-else>
        <div class="controls">
          <BaseButton @click="registerRegulation">
            <FontAwesomeIcon icon="fa-solid fa-plus" /> New
          </BaseButton>
        </div>
        <transition-group tag="ul" name="regulation-list">
          <RegulationItem 
            v-for="regulation in allRegulations" 
            :key="regulation.id" 
            :id="regulation.id"
            :name="regulation.name"
            :description="regulation.description"
            :associatedTable="regulation.associatedTable"
          />
        </transition-group>
        <p v-if="!error && !hasRegulations">You don't have any regulations yet, add some to start the Data Quality journey!</p>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import RegulationItem from '@/components/regulations/RegulationItem.vue'

export default {
  components: {
    RegulationItem
  },
  data() {
    return {
      error: null,
      isLoading: true,
    }
  },
  computed: {
    allRegulations() {
      return this.$store.getters['regulations/regulations']
    },
    hasRegulations() {
      const regulations = this.$store.getters['regulations/regulations']
      return regulations && regulations.length > 0
    }
  },
  methods: {
    registerRegulation() {
      this.$router.replace('/regulations/register')
    }
  },
  async created() {
    try {
      this.isLoading = true
      await this.$store.dispatch('regulations/loadRegulations')
      this.isLoading = false
    } catch(err) {
      this.isLoading = false
      this.error = err.message
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h2 {
  margin-bottom: 0;
}

.controls {
  text-align: right;
}

.regulation-list-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.regulation-list-leave-active {
  transition: all 1s ease-in;
  position: absolute;
}

.regulation-list-leave-to {
  opacity: 0;
  transform: translateX(60px);
}

.regulation-list-move {
  transition: transform 1s ease;
}
</style>